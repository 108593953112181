import {Injectable} from '@angular/core';
import {IObjectComplexSettings} from './settings.interface';

@Injectable()
export class ObjectComplexSettingsFrontService implements IObjectComplexSettings {

  public backrefUri = '/objects/search';

  public getBackrefUri(): string {
    return this.backrefUri;
  }

  public setBackrefUri(uri: string) {
    return this.backrefUri = uri;
  }

}
