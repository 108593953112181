<div class="form__group" [ngClass]="cssClass">
  <div class="form__label" [class.form__label_require]="require && !disabled" [class.form__label_error]="error">
    <ng-content></ng-content>
    <span class="ml-2 c-pointer"
          *ngIf="titleDescription"
          [title]="titleDescription"
    >
      <i class="fa fa-2x fa-question-circle"></i>
    </span>
  </div>
  <div class="form__input flex-nowrap"
       [class.input-group]="appendHtml || descriptionTitle"
       [class.form__input_disabled]="disabled"
       [class.form__input_focus]="focus"
       [class.form__input_error]="error"
       (mouseenter)="hovered = true"
       (mouseleave)="hovered = false">
    <div class="input-group-prepend c-pointer"
         *ngIf="descriptionTitle"
         [popover]="descriptionTitle"
         [popoverPlacement]="'left'"
         [popoverCloseOnClickOutside]="true"
    >
      <i class="fa fa-question-circle"></i>
    </div>
    <input [type]="type" class="input form-control" [attr.autocomplete]="!autofill ? 'new-password' : null"
           [value]="current_value" [disabled]="disabled" (keyup)="onChanges($event.target)"
           (focus)="focus = true"
           (blur)="focus = false"
           [attr.maxlength]="maxLength"
           [ngClass]="{'md-valid': current_value && (current_value.length || current_value > 0)}"
           [placeholder]="placeholder"/>
    <div class="input-group-append" *ngIf="appendHtml"><span class="input-group-text" [innerHtml]="appendHtml"></span>
    </div>
    <ng-container *ngIf="remove && current_value && !disabled && hovered">
      <button class="form__input-close" (click)="onRemove($event)" type="button" tabindex="-1">×</button>
    </ng-container>
  </div>
</div>
