import {Pipe, PipeTransform} from '@angular/core';


@Pipe({
  name: 'numberRank'
})
export class NumbersPipe implements PipeTransform {

  transform(value: any, ...args: any[]) {
    value = value.toFixed(0);

    const additional_class = args[0] ? `price__wrapper__devider_${args[0]}` : '',
          global_class = `price__wrapper__devider ${additional_class}`;

    return '<span class="number__wrapper">' + value.toString()
      .replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '<span class="' + global_class + '">$1</span>') +
      '</span>';
  }

}


@Pipe({
  name: 'clearNum'
})
export class ClearNumPipe implements PipeTransform {
  _re = /[^\d.,]/;
  transform(value: any, ...args: any[]) {
    value = ('' + value).replace(this._re, '');
    return parseFloat(value.replace(',', '.'));
  }

}
