<ng-container *ngIf="show">
  <div class="modal d-block" [@slideUp] [class.in]="show" tabindex="-1" role="dialog" [ngClass]="backdropClass">
    <div class="modal__mobile-header">
      <i class="modal__mobile-header__icon icon icon_Small_Dropdown_White mr-2" data-dismiss="modal" aria-label="Close" (click)="onClose()"></i>{{caption}}
    </div>
    <div class="modal-dialog"
         [class.modal-full]="size==='full'"
         [class.modal-xl]="size==='xl'"
         [class.modal-lg]="size==='large'"
         [class.modal-sm]="size==='small'" role="document">
      <div class="modal-content modal__content" #content [ngClass]="cssClass">
        <div class="modal-header modal__header" *ngIf="caption">
          {{caption}}
          <ng-template [ngTemplateOutlet]="header_button"></ng-template>
        </div>
        <div class="modal-body modal__body">
          <ng-container *ngIf="!caption">
            <ng-template [ngTemplateOutlet]="header_button"></ng-template>
          </ng-container>
          <ng-content></ng-content>
        </div>
        <div class="modal-footer modal__footer" *ngIf="footer">
          <ng-template [ngTemplateOutlet]="footer"></ng-template>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-backdrop in modal-stack" [@fade] [ngClass]="backdropClass"></div>
</ng-container>


<ng-template #header_button>
  <div class="">
    <button type="button" class="close modal__close c-pointer" data-dismiss="modal" aria-label="Close" (click)="onClose()">
      <i class="icon icon_Small_Close"></i>
    </button>
  </div>
</ng-template>
