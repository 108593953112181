import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {AccordionWrapperComponent} from './component/accordion-wrapper-component';
import {AccordionElementComponent} from './component/accordion-element.component';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    AccordionWrapperComponent,
    AccordionElementComponent,
  ],
  exports: [
    AccordionWrapperComponent,
    AccordionElementComponent,
  ]
})
export class AccordionModule { }
