import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'color'
})
export class ColorPipe implements PipeTransform {

  transform(color: string, type: string) {

    color = (color + '').trim();

    switch (type) {
      case 'toRGB': {
        return this.toRGB(color);
      }
      case 'toHEX': {
        return this.toHEX(color);
      }
      case 'HEXtoLUMA': {
        return this.HEXtoLUMA(color);
      }
    }
  }

  public toRGB(color) {
    let rgb = null;
    const match = color.match(/^#?(([0-9a-fA-F]{3}){1,3})$/);

    if (!match) { return null; }

    rgb = {};

    color = match[1];

    if (color.length === 6) {
      rgb.r = parseInt(color.substring(0, 2), 16);
      rgb.g = parseInt(color.substring(2, 4), 16);
      rgb.b = parseInt(color.substring(4, 6), 16);
    } else if (color.length === 3) {
      rgb.r = parseInt(color.substring(0, 1) + color.substring(0, 1), 16);
      rgb.g = parseInt(color.substring(1, 2) + color.substring(1, 2), 16);
      rgb.b = parseInt(color.substring(2, 3) + color.substring(2, 3), 16);
    }

    rgb.css = 'rgb(';
    rgb.css += rgb.r + ',' + rgb.g + ',' + rgb.b;
    rgb.css += ')';

    if (rgb.css.indexOf('undefined') < 0) {
      return rgb.css;
    } else {
      return null;
    }

  }

  public toHEX(color) {
    if (!color || color.indexOf('rgb') < 0 || color.indexOf('NaN') > 0) {
      return;
    }

    if (color.charAt(0) === '#') {
      return color;
    }

    const nums = /(.*?)rgb\((\d+),\s*(\d+),\s*(\d+)\)/i.exec(color),
      r = parseInt(nums[2], 10).toString(16),
      g = parseInt(nums[3], 10).toString(16),
      b = parseInt(nums[4], 10).toString(16);

    const hex = '#' + (
      (r.length === 1 ? '0' + r : r) +
      (g.length === 1 ? '0' + g : g) +
      (b.length === 1 ? '0' + b : b)
    );

    if (hex.indexOf('undefined') < 0) {
      return hex;
    } else {
      return null;
    }

  }

  public HEXtoLUMA(hex) {

    hex = hex.replace('#', '');

    const hexArray = [];

    if (hex.length > 3) {
      hexArray[0] = hex.substring(0, 2);
      hexArray[1] = hex.substring(2, 4);
      hexArray[2] = hex.substring(4, 6);
    } else {
      hexArray[0] = hex.substring(0, 1).repeat(2);
      hexArray[1] = hex.substring(1, 2).repeat(2);
      hexArray[2] = hex.substring(2, 3).repeat(2);
    }

    return (
      (parseInt(hexArray[0], 16) * 299) +
      (parseInt(hexArray[1], 16) * 587) +
      (parseInt(hexArray[2], 16) * 114)
    ) / 1000;

  }

}
