import {Injectable} from '@angular/core';
import {ISettings} from './settings.interface';

@Injectable()
export class HousingSettingsFrontService implements ISettings {

  public getHousingUri(): string {
    return '/objects';
  }

  public getBackrefUri(): string {
    return '/objects/search';
  }

}
