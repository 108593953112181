
import {tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {LockerService} from '../../../services/locker.service';

@Injectable()
export class JsonErrorHandler303Interceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(tap((event: HttpEvent<any>) => {}, e => {
      if (e.status === 303) {
        LockerService.server_down();
      }
    }));
  }

}
