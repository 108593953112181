///<reference path="directives/td-compare.directive.ts"/>
import { NgModule } from '@angular/core';
import { PanelModule } from '../modules/@panel';
import { AlertModule } from '../modules/@alert';
import { PhonePipe } from '../pipes/phone.pipe';
import { PriceRankPipe } from '../pipes/prices.pipe';
import { ModalModule } from '../modules/@modal';
import {ClearNumPipe, NumbersPipe} from '../pipes/numbers.pipe';
import { KeysPipe } from '../pipes/keys.pipe';
import { BackgroundImageDirective } from './directives/background-image.directive';
import { ColorPipe } from '../pipes/color.pipe';
import {PartRatePipe} from '../pipes/part-rate.pipe';
import {CopyTextDirective} from './directives/copy-text.directive';
import {TextPreviewDirective} from './directives/text-preview.directive';
import {PriceNamePipe} from '../pipes/prices-name.pipe';
import {TdCompareDirective} from './directives/td-compare.directive';
import {ReplaceAllPipe} from '../pipes/replaceAll.pipe';
import {ToZeroPipe} from '../pipes/toZero.pipe';
import {ToFixed2Pipe} from '../pipes/toFixed2.pipe';
import {ScrollSpyDirective} from './directives/scroll-spy.directive';
import {MouseWheelDirective} from './directives/mouse-wheel.directive';
import {DragHelperDirective} from './directives/drag-helper.directive';
import {ScrollManagerDirective} from './directives/scroll-manager/scroll-manager.directive';
import {ScrollAnchorDirective} from './directives/scroll-manager/scroll-anchor.directive';
import {ScrollSectionDirective} from './directives/scroll-manager/scroll-section.directive';

@NgModule({
  declarations: [
    PriceRankPipe,
    PriceNamePipe,
    NumbersPipe,
    ClearNumPipe,
    PhonePipe,
    ColorPipe,
    PartRatePipe,
    KeysPipe,
    ReplaceAllPipe,
    ToZeroPipe,
    ToFixed2Pipe,
    BackgroundImageDirective,
    CopyTextDirective,
    TextPreviewDirective,
    TdCompareDirective,
    ScrollSpyDirective,
    MouseWheelDirective,
    DragHelperDirective,
    ScrollManagerDirective,
    ScrollAnchorDirective,
    ScrollSectionDirective,
  ],
  imports: [
    PanelModule,
    AlertModule,
    ModalModule,
  ],
  exports: [
    PanelModule,
    AlertModule,
    ModalModule,
    PriceRankPipe,
    PriceNamePipe,
    PhonePipe,
    ColorPipe,
    PartRatePipe,
    NumbersPipe,
    ClearNumPipe,
    KeysPipe,
    ReplaceAllPipe,
    ToZeroPipe,
    ToFixed2Pipe,
    BackgroundImageDirective,
    CopyTextDirective,
    TextPreviewDirective,
    TdCompareDirective,
    ScrollSpyDirective,
    MouseWheelDirective,
    DragHelperDirective,
    ScrollManagerDirective,
    ScrollAnchorDirective,
    ScrollSectionDirective,
  ],
  providers: [
    PriceRankPipe,
    PhonePipe,
    NumbersPipe,
    ClearNumPipe,
    KeysPipe,
    ColorPipe,
    PartRatePipe,
  ],
})
export class SharedModule { }
