import {NgModule} from '@angular/core';
import {Error400Component} from './error-400.component';
import {PanelModule} from '../../modules/@panel/panel.module';

@NgModule({
  imports: [
    PanelModule,
  ],
  declarations: [
    Error400Component,
  ],
  exports: [
    Error400Component,
  ]
})
export class Error400Module {

}
