import {Injectable} from '@angular/core';
import {IObjectHousingSettings} from './settings.interface';

@Injectable()
export class ObjectHousingSettingsEmbededService implements IObjectHousingSettings {

  public backrefUri = '/home/objects';
  public basefUri = '/home';

  public getBackrefUri(): string {
    return this.backrefUri;
  }

  public setBackrefUri(uri: string) {
    return this.backrefUri = uri;
  }

  public setBackrefDefault() {
    return this.backrefUri = '/home/objects';
  }
}
