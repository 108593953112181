import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {UUID} from 'angular2-uuid';
import {Observable} from 'rxjs';
import {NotificationService} from '../../services/notification.service';


@Injectable()
export class JsonClientService {

  private readonly api_url = environment.apiUrl;
  private readonly simulation_url = 'http://sym-dev.spn24.ru/simulation';

  private static makeRequest(method, data) {
    return {
      'id': UUID.UUID(),
      'jsonrpc': '2.0',
      'method': method,
      'params': data
    };
  }

  constructor(private http: HttpClient,
              private notify: NotificationService) {
  }

  public postRequest(method: string, data: any = {}, raw_data = false,
                     is_simulation = false): Promise<any> {
    /*
     *
     */
    let resolver = (...args) => null;
    let rejecter = (...args) => null;

    const server_url = is_simulation ? this.simulation_url : this.api_url;
    const promise = new Promise<any>((resolve, reject) => {
      resolver = resolve;
      rejecter = reject;
    });

    const request = JsonClientService.makeRequest(method, data);

    this.http.post(server_url, request).subscribe(
      response => {
        if (!response['error']) {
          resolver(response);
        } else {
          rejecter(response);
        }
      },
      error => {
        if (
          error && error.error
          && error.error.error
          && error.error.error.data
          && error.error.error.data.clear_message
        ) {
          this.notify.notifyError('Ошибка', error.error.error.data.clear_message, 5000);
        } else if (!(error.status === 401 || error.error && error.error.code === 40010)) {
          this.notify.notifyError('Ошибка', error, 5000);
        }
        rejecter(error);
      }
    );

    return promise;
  }

  public postObs(method: string, data: any = {}): Observable<any> {
    /*
     *
     */
    const server_url = this.api_url,
      _data = JsonClientService.makeRequest(method, data);

    return this.http.post(server_url, _data);
  }

  public post(method: string, data: any = {}): Promise<any> {
    /*

     */
    let resolver: (...args) => void;
    let rejecter: (...args) => void;
    const promise = new Promise<any>((resolve, reject) => {
      resolver = resolve;
      rejecter = reject;
    });

    this.postRequest(method, data)
      .then(response => {
        if (!environment.production &&
          response['error'] &&
          response['error']['name'] === 'MethodNotFoundError' &&
          this.simulation_url) {
          this.postRequest(method, data, false, true)
            .then(_response => resolver(_response['result']))
            .catch(_ => rejecter());

          return;
        }

        resolver(response['result']);
      })
      .catch(error => rejecter(error));

    return promise;

  }

}
