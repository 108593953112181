import { EventEmitter, Injectable } from '@angular/core';

@Injectable()
export class MaterialSelect2Service {

  public onReload: EventEmitter<any> = new EventEmitter<any>();
  public onClear: EventEmitter<any> = new EventEmitter<any>();
  public onChange: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
  }

  reload() {
    this.onReload.emit(true);
  }

  clear() {
    this.onClear.emit(true);
  }

  change(data) {
    this.onChange.emit(data);
  }
}
