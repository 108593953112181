import {Injectable} from '@angular/core';
import {ISessionInject} from '../models/session-inject';

@Injectable()
export class HttpSessionHashService implements ISessionInject {

  private sessionID: string;

  public token_name = 'WWWUUID';

  public getHeaderCaption(): string {
    return this.token_name;
  }

  public setSessionID(value: string): void {
    this.sessionID = value;
  }

  public getSessionID(): string {
    return this.sessionID;
  }

}
