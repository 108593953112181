import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'priceRank'
})
export class PriceRankPipe implements PipeTransform {

  transform(value: any, ...args: any[]) {

    const [
      args_additional_class,
      args_wrapper_class,
      args_currency_class,
      args_number_class,
      args_to_fixed,
      args_no_rouble,
    ] = args;

    if (typeof value === 'undefined' || !value) {
      return '';
    } else {
      value = value.toFixed(args_to_fixed || 0);
    }
    let rouble = '&nbsp;₽';
    if (args_no_rouble) {
      rouble = '';
    }
    const additional_class = args_additional_class ? `price__wrapper__devider_${args_additional_class}` : '';
    const global_class = `price__wrapper__devider ${additional_class}`;
    const wrapper_class = args_wrapper_class ? ` price__wrapper_${args_wrapper_class}` : '';
    const currency_class = args_currency_class ? ` price__wrapper__currency_${args_currency_class}` : '';
    const number_class = args_number_class ? ` price__wrapper__number_${args_number_class}` : '';

    return '<span class="price__wrapper' + wrapper_class + '">' +
        '<span class="price__wrapper__number' + number_class + '">' +
          value.toString().replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '<span class="' + global_class + '">$1</span>') +
        '</span>' +
        '<span class="price__wrapper__currency' + currency_class + '">' + rouble + '</span>' +
      '</span>';
  }

}
