import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'partRate'
})
export class PartRatePipe implements PipeTransform {

  transform(agent: any, subagent: any, type: string = 'back') {
    if (agent === '' && subagent !== '') {
      agent = 0;
    }
    if (agent === '' && subagent === '') {
      return '';
    }
    agent = parseFloat((agent + '').replace(',', '.'));
    subagent = parseFloat((subagent + '').replace(',', '.'));

    let part: any;
    part = (subagent / agent).toFixed(5);

    if (type === 'front') {
      if (part > 1) {
        part = 'НЕВЕРНЫЕ ДАННЫЕ';
      }
    }
    if (part === 'NaN') {
      part = '0';
    }

    return part;
  }

}
