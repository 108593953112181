import {Injectable} from '@angular/core';
import {NotificationService} from '../../../../services/notification.service';

@Injectable()
export class DeactivateService {

  public testChange(was_changed) {
    if (was_changed) {
      return NotificationService.swalConfirm('Внимание', 'Все несохраненные изменения будут утеряны. Покинуть страницу?')
        .then(() => true)
        .catch(() => false);
    }
  }

}
