import {EventEmitter, Injectable} from '@angular/core';
import {AuthenticateService} from './authenticate.service';

@Injectable()
export class LockerService {

  private static _locked = false;
  public static state_emitter: EventEmitter<boolean> = new EventEmitter();

  private static _server_downed = false;
  public static downed_emitter: EventEmitter<boolean> = new EventEmitter();

  public static set locked(value: boolean) {
    this._locked = value;
    this.state_emitter.emit(this._locked);
  }

  public static get locked(): boolean {
    return this._locked;
  }

  public static lock() {
    this.locked = true;
    // AuthenticateService.exit();
  }

  public static unlock() {
    this.locked = false;
  }

  public static set server_downed(value: boolean) {
    this._server_downed = value;
    this.downed_emitter.emit(this._server_downed);
  }

  public static get server_downed(): boolean {
    return this._server_downed;
  }

  public static server_down() {
    this.server_downed = true;
  }

  public static server_up() {
    this.server_downed = false;
  }

}
