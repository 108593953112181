import {Injectable, EventEmitter} from '@angular/core';
import {NotifyQueue, NotifyInfo, NotifyWarning, ABSNotification, NotifySuccess} from '../models/notificaction';

declare var swal: any;


@Injectable()
export class NotificationService {
    /* Queue Notification (CRM)*/
    public _queue: NotifyQueue = new NotifyQueue();
    set queue(queue: NotifyQueue) { }
    get queue(): NotifyQueue { return this._queue; }
    /* Alert Notification (CRM + Front)*/
    public _alert: NotifyQueue = new NotifyQueue();
    set alert(alert: NotifyQueue) { }
    get alert(): NotifyQueue { return this._alert; }
    /* Message Notification (Front)*/
    public _message: NotifyQueue = new NotifyQueue();
    set message(message: NotifyQueue) { }
    get message(): NotifyQueue { return this._message; }

    private audio: any = {info: new Audio(), error: new Audio()};
    private _status = false;

    public queue_emitter: EventEmitter<NotifyQueue> = new EventEmitter<NotifyQueue>();
    public alert_emitter: EventEmitter<NotifyQueue> = new EventEmitter<NotifyQueue>();
    public message_emitter: EventEmitter<NotifyQueue> = new EventEmitter<NotifyQueue>();
    public status_emitter: EventEmitter<boolean> = new EventEmitter();

    constructor() {
        this.audio.info.src = '/assets_common/sounds/success.mp3';
        this.audio.error.src = '/assets_common/sounds/error.mp3';
    }

    private set status(value: boolean) {
      this._status = value;
      this.status_emitter.emit(this._status);
    }

    private get status(): boolean {
      return this._status;
    }

    public static swalSuccess(subject: string, body: string) {
      swal({
        title: subject,
        text: body,
        type: 'success',
        buttonsStyling: false,
        confirmButtonClass: 'btn btn-primary'
      });
    }

    public static swalError(subject: string, body: string) {
      swal({
        title: subject,
        text: body,
        type: 'error',
        buttonsStyling: false,
        confirmButtonClass: 'btn btn-primary'
      });
    }

    public static swalConfirm(subject: string,
                              body: string,
                              confirmButtonText: string = 'Выполнить',
                              cancelButtonText: string = 'Отмена',
                              confirmButtonClass: string = 'btn btn-danger',
                              cancelButtonClass: string = 'btn btn-secondary') {
      return swal({
        title: subject,
        html: body,
        type: 'warning',
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: confirmButtonText,
        cancelButtonText: cancelButtonText,
        confirmButtonClass: confirmButtonClass,
        cancelButtonClass: cancelButtonClass
      });
    }

    public static swalSelect(
      subject: string,
      data: Array<object>,
      confirmButtonText: string = 'Выполнить',
      cancelButtonText: string = 'Отмена',
      confirmButtonClass: string = 'btn btn-danger',
      cancelButtonClass: string = 'btn btn-secondary'
    ) {

      return swal({
        title: subject,
        input: 'select',
        type: 'warning',
        inputOptions: data,
        showCancelButton: true,
        confirmButtonText: confirmButtonText,
        cancelButtonText: cancelButtonText,
        confirmButtonClass: confirmButtonClass,
        cancelButtonClass: cancelButtonClass,
        inputValidator: function (value) {
          return new Promise<void>(function (resolve, reject) {
            if (value !== '') {
              resolve();
            } else {
              reject('Необходимо выбрать опцию!');
            }
          });
        }
      })

    }

    public static swalInput(
      subject: string,
      data: any,
      confirmButtonText: string = 'Выполнить',
      cancelButtonText: string = 'Отмена',
      confirmButtonClass: string = 'btn btn-danger',
      cancelButtonClass: string = 'btn btn-secondary'
    ) {

      return swal({
        title: subject,
        input: 'text',
        type: 'warning',
        inputValue: data['value'],
        showCancelButton: true,
        confirmButtonText: confirmButtonText,
        cancelButtonText: cancelButtonText,
        confirmButtonClass: confirmButtonClass,
        cancelButtonClass: cancelButtonClass,
        inputValidator: function (value) {
          return new Promise<void>(function (resolve, reject) {
            if (value !== '') {
              resolve();
            } else {
              reject('Необходимо выбрать опцию!');
            }
          });
        }
      })

    }


    public toggleStatus() {
      this.status = !this.status;
      return this.status;
    }

    queueInfo(subject: string, body: string = '', timer: number = 0): NotifyInfo {
        const notification = new NotifyInfo(subject, body);
        this.queue.push(notification);
        this.queue_emitter.emit(this.queue);
        this.playBeep();
        if (timer) {
          setTimeout(() => {
            this.splice(notification);
          }, timer);
        }
        return notification;
    }

    queueSuccess(subject: string, body: string = '', timer: number = 0): NotifySuccess {
        const notification = new NotifySuccess(subject, body);
        this.queue.push(notification);
        this.queue_emitter.emit(this.queue);
        this.playError();
        if (timer) {
          setTimeout(() => {
            this.splice(notification);
          }, timer);
        }
        return notification;
    }

    queueError(subject: string, body: string = '', timer: number = 0): NotifyWarning {
        const notification = new NotifyWarning(subject, body);
        this.queue.push(notification);
        this.queue_emitter.emit(this.queue);
        this.playError();
        if (timer) {
          setTimeout(() => {
            this.splice(notification);
          }, timer);
        }
        return notification;
    }

    public splice(notification) {
      this.queue.splice(notification);
      this.queue_emitter.emit(this.queue);
    }

    notifyInfo(subject: string, body: string = '', timer: number = 0, link?: string): NotifyInfo {
        const notification = new NotifyInfo(subject, body, link);
        this.alert.push(notification);
        this.alert_emitter.emit(this.alert);
        this.playBeep();
        if (timer) {
          setTimeout(() => {
            this.cleanNotify(notification);
          }, timer);
        }
        return notification;
    }

    notifySuccess(subject: string, body: string = '', timer: number = 0, link?: string): NotifySuccess {
        const notification = new NotifySuccess(subject, body, link);
        this.alert.push(notification);
        this.alert_emitter.emit(this.alert);
        this.playError();
        if (timer) {
          setTimeout(() => {
            this.cleanNotify(notification);
          }, timer);
        }
        return notification;
    }

    notifyError(subject: string, body: string = '', timer: number = 0, link?: string): NotifyWarning {
        const notification = new NotifyWarning(subject, body, link);
        this.alert.push(notification);
        this.alert_emitter.emit(this.alert);
        this.playError();
        if (timer) {
          setTimeout(() => {
            this.cleanNotify(notification);
          }, timer);
        }
        return notification;
    }

    public cleanNotify(notification: ABSNotification) {
        this.alert.splice(notification);
        this.alert_emitter.emit(this.alert);
    }

    messageInfo(body: string = '', timer: number = 0): NotifyInfo {
        const notification = new NotifyInfo('', body);
        this.message.push(notification);
        this.message_emitter.emit(this.message);
        if (timer) {
          setTimeout(() => {
            this.cleanMessage(notification);
          }, timer);
        }
        return notification;
    }

    messageSuccess(body: string = '', timer: number = 0): NotifySuccess {
        const notification = new NotifySuccess('', body);
        this.message.push(notification);
        this.message_emitter.emit(this.message);
        if (timer) {
          setTimeout(() => {
            this.cleanMessage(notification);
          }, timer);
        }
        return notification;
    }

    messageError(body: string = '', timer: number = 0): NotifyWarning {
        const notification = new NotifyWarning('', body);
        this.message.push(notification);
        this.message_emitter.emit(this.message);
        if (timer) {
          setTimeout(() => {
            this.cleanMessage(notification);
          }, timer);
        }
        return notification;
    }

    public cleanMessage(notification: ABSNotification) {
        this.message.splice(notification);
        this.message_emitter.emit(this.message);
    }

    emptyQueue(): void {
        this._queue = new NotifyQueue();
        this.queue_emitter.emit(this.queue);
    }

    playBeep(): void {
        // this.audio.info.play();
    }

    playError(): void {
        // this.audio.error.play();
    }

}
