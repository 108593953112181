import {Injectable} from '@angular/core';
import {HttpClient} from './http.service';

@Injectable()
export class DictionariesService {

  private cache: {[key: string]: any} = {};

  constructor(private http: HttpClient) { }

  get(api_key: string): Promise<any> {
    let promiseResolver: (value: any) => any;
    const promise = new Promise<any>((resolve) => promiseResolver = resolve);

    if (-1 === Object.keys(this.cache).indexOf(api_key)) {
      this.http.post(api_key).subscribe(
        (response) => {
          this.cache[api_key] = response;
          promiseResolver(response);
        },
        (reject) => promiseResolver(null)
      );
    } else {
      promiseResolver(this.cache[api_key]);
    }

    return promise;
  }

  unset(api_key: string) {
    if (Object.keys(this.cache).indexOf(api_key) !== -1) {
      delete this.cache[api_key];
      return true;
    }

    return false;
  }
}
