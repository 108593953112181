import { EventEmitter, Injectable, Optional } from '@angular/core';
import { environment } from '../../environments/environment';
import { EmbededService } from './embeded.service.';
import { ClientModeService } from '../_front/client-mode/client-mode.service';


@Injectable()
export class ConfigService {

  public static key = '5b5bf7a3-e949-4cee-9b4f-b14b864477b9';

  public settings_emitter: EventEmitter<any> = new EventEmitter<any>();
  public client_mode_emitter: EventEmitter<boolean> = new EventEmitter();
  public news_show_emitter: EventEmitter<boolean> = new EventEmitter();

  public _data: {[key: string]: any} = null;
  private has_client = false;

  public _news: {[key: string]: any} = null;
  public news_show = false;
  public map_info: {[key: string]: any} = null;

  private _default: {[key: string]: any} = {
    'api_host': environment.apiServer,
    'api_url': environment.apiUrl,
    // Телефон консультации
    'phone_consul': '+7 (495) 505 55 20',
    // Адрес офиса
    'addr_office': '142400, Московская область, г. Ногинск, ул. 3 Интернационала, д. 78, пом. 4-13 для ООО «ОнТИ»',
    'url_file_upload': environment.urlFileUpload,
    'url_price_housing': environment.urlPriceHousing,
    'url_presentatio_complex': environment.urlPresentationComplex,
    'url_presentatio_house': environment.urlPresentationHouse,
    'url_presentatio_flat': environment.urlPresentationFlat,
    'url_lead': environment.urlLead,
    // Режим работы с клиентом (по умолчанияю: включена)
    'client_mode': true,
    'client_current': {},
    'search_type': null,
  };

  get data(): any {
    if (!this._data) {
      const cache_data = localStorage.getItem('spn-web1-' + ConfigService.key);
      this._data = cache_data ? JSON.parse(cache_data) : this._default;
    }

    return this._data;
  }

  set data(data: any) {
    this._data = data;
    this.saveData();
    this.settings_emitter.emit(this._data);
  }

  public reset(): void {
    this.client_mode_emitter.emit(false);
    localStorage.setItem('spn-web1-' + ConfigService.key, JSON.stringify(this._default));
  }

  public static hasCache(): boolean {
    return localStorage.getItem('spn-web1-' + ConfigService.key) != null;
  }

  public setData(key, value) {
    const data = this.data;
    data[key] = value;
    this.data = data;
  }

  public setClientMode(value: boolean, notice = true) {
    const data = this.data,
      disable_client = () => {
        data.client_mode = value;
        this.data = data;
        this.client_mode_emitter.emit(value);
      };
    disable_client();
  }

  public saveData() {
    localStorage.setItem('spn-web1-' + ConfigService.key, JSON.stringify(this.data));
  }

  public getClientMode(): boolean {
    const data = this.data;
    return data.client_mode;
  }

  /* News */
  get news(): any {
    if (!this._news) {
      const cache_data = localStorage.getItem('spn-news-' + ConfigService.key);
      this._news = cache_data ? JSON.parse(cache_data) : {'date': null};
    }
    return this._news;
  }

  set news(news: any) {
    this._news = news;
    localStorage.setItem('spn-news-' + ConfigService.key, JSON.stringify(this._news));
  }

  public setShowNews(news_show) {
    this.news_show_emitter.emit(news_show);
  }

  public getShowNews() {
    const cache_data = localStorage.getItem('spn-lending');
    const data = cache_data ? JSON.parse(cache_data) : {};
    if (data['news_show']) {
      this.news_show = data['news_show'];
    }
    localStorage.removeItem('spn-lending');
  }

  constructor(@Optional() private embeddedService: EmbededService, @Optional() private clientService: ClientModeService) {
    if (this.embeddedService != null) {
      this.setClientMode(true);
    }

    if (this.clientService != null) {
      this.clientService.clientEmitter.subscribe(x => this.has_client = x !== null);
    }

    this.getShowNews();
    this.news_show_emitter.subscribe(x => {
      this.news_show = x;
    });

    const mutationObserver = new MutationObserver(() => {
    });

    mutationObserver.observe(document.body, {'childList': true, 'subtree': true});
  }

}
