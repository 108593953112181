<div #popoverDiv class="popover {{ effectivePlacement }}"
     [style.top]="top + 'px'"
     [style.left]="left + 'px'"
     [class.in]="isIn"
     [class.fade]="animation"
     [class.hide]="top < -9999"
     style="display: block"
     role="popover">
  <div [hidden]="!closeOnMouseOutside" class="virtual-area"></div>
  <i (click)="close_hide()" class="icon icon_Small_Close d-inline-sm-fix d-md-none mt-1 mr-1 float-right"></i>
  <div class="arrow"></div>
  <h3 class="popover-title" [hidden]="!title" [innerHTML]="title"></h3>
  <div class="popover-content">
    <ng-content></ng-content>
    {{ content }}
  </div>
</div>
