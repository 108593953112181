
import {from as observableFrom, BehaviorSubject, Observable, Subject} from 'rxjs';
import {HostListener, Injectable} from '@angular/core';

type ScreenSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
type Device = 'mobile' | 'tablet' | 'desktop';


@Injectable()
export class UserAgentService {

  private _subject: BehaviorSubject<ScreenSize> = new BehaviorSubject(null);
  public readonly change = observableFrom(this._subject);

  private static getScreenSize(): ScreenSize {
    if (document.body.clientWidth < 576) {
      return 'xs';
    }

    if (document.body.clientWidth >= 576 && document.body.clientWidth < 768) {
      return 'sm';
    }

    if (document.body.clientWidth >= 768 && document.body.clientWidth < 992) {
      return 'md';
    }

    if (document.body.clientWidth >= 992 && document.body.clientWidth < 1200) {
      return 'lg';
    }

    return 'xl';
  }

  constructor() {
    this._subject.next(UserAgentService.getScreenSize());

    window.addEventListener('resize', (event) => {
      this._subject.next(UserAgentService.getScreenSize());
    });

    window.addEventListener('orientationchange', (event) => {
      this._subject.next(UserAgentService.getScreenSize());
    });
  }

  public get screen_size(): ScreenSize {
    return this._subject.getValue();
  }

  public get device(): Device {
    if (['md', 'lg', 'xl'].indexOf(this.screen_size) !== -1) {
      return 'desktop';
    }

    return 'xs' == this.screen_size ? 'mobile' : 'tablet';
  }

  public get is_mobile(): boolean {
    return this.device === 'mobile' || this.device === 'tablet';
  }

}
