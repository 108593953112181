import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ValidHashGuard} from './guards/valid-hash.guard';
import {Error400Component} from './errors/400-bad-request/error-400.component';
import {Error403Component} from './errors/403-forbidden/error-403.component';

const routes: Routes = [
  {path: 'home', loadChildren: () => import('app/_embeded/home/embeded-home.module').then(
    m => m.EmbededHomeModule), canActivate: [ValidHashGuard]},
  {path: '400', component: Error400Component, pathMatch: 'full'},
  {path: '403', component: Error403Component, pathMatch: 'full'},
  {path: '', redirectTo: 'home', pathMatch: 'full'},
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
  ],
  exports: [
    RouterModule,
  ]
})
export class AppEmbededRoutingModule {

}
