import {Injectable} from '@angular/core';

declare const jQuery;

@Injectable()
export class FancyboxService {

  public open(albums, index, ...opts) {
    jQuery.fancybox.open(albums.map(x => {
      return {
        src: x['src'],
        opts : {
          thumb   : x['src']
        }
      };
    }), {
      loop : false,
      thumbs : {
        autoStart   : true,
        axis        : 'y'
      },
      afterLoad : function(instance, current) {

        const svgRe = /\.(svg|png|gif|webp)$/i;
        if (svgRe.test(current.src)) {
          const ratio = current.width / current.height;

          if (ratio < 1) {
            current.height = Math.round(window.screen.height || 1920);
            current.width = Math.round(current.height * ratio);
          } else {
            current.width = Math.round(window.screen.width || 1080);
            current.height = Math.round(current.width / ratio);
          }
          current.$content.addClass('bg-white');
        }

      },
    }, index || 0);
  }

}
