import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  TemplateRef,
  ViewChild,
  Inject
} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { FADE_ANIMATION } from '../../animations/fade.animation';
import { SLIDE_UP_ANIMATION } from '../../animations/slide-up.animation';
import {DeactivateService} from '../../shared/housing/shared/guards';
import { DOCUMENT } from '@angular/common';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';


@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  animations: [SLIDE_UP_ANIMATION, FADE_ANIMATION]
})
export class ModalComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() public backdropClass = '';
  @Output() public onClosed = new EventEmitter();
  @Input() public caption: string = null;
  @Input() public cssClass = '';
  @Input() public was_changed = false;
  @Input() public size = 'large';
  @Input() public closeOutside = true;
  @Input() public footer: TemplateRef<any>;
  @Input() private withoutCloseOnUrlChange = false;
  @ViewChild('content') public content: ElementRef;

  startingTimeoutEnds = false;

  subscription: Subscription = new Subscription();

  constructor(private router: Router, private renderer: Renderer2,
              private _elementRef: ElementRef, private cd: ChangeDetectorRef, public DS: DeactivateService,
              @Inject(DOCUMENT) private _document: HTMLDocument) { }

  public show = true;

  ngOnInit() {
    if (this.withoutCloseOnUrlChange !== true) {
      this.subscription = this.router.events.pipe(filter(x => x instanceof NavigationEnd))
        .subscribe(x => {
          if (this.startingTimeoutEnds) {
            this.onClose();
            this.safeRemoveClass();
          }
        });
    }
    this.renderer.addClass(document.body, 'modal-open');
    window.setTimeout(() => {
      this.startingTimeoutEnds = true;
    }, 3000);
  }

  ngAfterViewInit() {
    this.cd.detectChanges();
  }

  ngOnDestroy() {
    this.show = false;
    this.safeRemoveClass();
    this.subscription.unsubscribe();
    if (this.cd) {
      this.cd.detach();
    }
  }

  public onClose() {
    if (this.was_changed) {
      Promise.resolve(this.DS.testChange(this.was_changed)).then(result => {
        if (result) {
          this.show = false;
          this.was_changed = false;
          this.onClosed.emit();
          this.safeRemoveClass();
        }
      });
    } else {
      this.show = false;
      this.was_changed = false;
      this.onClosed.emit();
      this.safeRemoveClass();
    }
  }

  public onCloseAnimate() {
    setTimeout(() => {
      this.onClosed.emit();
      this.safeRemoveClass();
    }, 300);
  }

  onOutside() {
    if (this.closeOutside) {
      this.onClose();
      this.safeRemoveClass();
    }
  }

  @HostListener('document:click', ['$event', '$event.target'])
  public onClick(event: MouseEvent, targetElement: HTMLElement) {
    if (this.closeOutside && this.show && targetElement) {
      const clickedInside = this.content.nativeElement.contains(targetElement);
      if (!clickedInside) {
        this.onOutside();
      }
    }
  }

  private safeRemoveClass() {
    if (this._document.querySelectorAll('.modal').length > 1) {
      return;
    }
    this.renderer.removeClass(document.body, 'modal-open');
  }

}
