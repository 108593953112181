import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { Router } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ClientModeService } from './_front/client-mode/client-mode.service';
import { AppEmbededRoutingModule } from './app-embeded-routing.module';
import { AppEmbededComponent } from './app-embeded.component';
import { Error400Module } from './errors/400-bad-request/error-400.module';
import { Error403Module } from './errors/403-forbidden/error-403.module';
import { ValidHashGuard } from './guards/valid-hash.guard';
import { FlatinfoEmbededComponent } from './modules/@object-flatinfo/component/flatinfo-embeded.component';
import { FlatinfoEmbededModule } from './modules/@object-flatinfo/flatinfo-embeded.module';
import { ConfigService } from './services/configuration.service';
import { DictionariesService } from './services/dictionaries.service';
import { EmbededService } from './services/embeded.service.';
import { HttpSessionHashService } from './services/http-session-hash.service';
import { HttpSessionService } from './services/http-session.service';
import { HttpClient } from './services/http.service';
import { LockerService } from './services/locker.service';
import { TemplateService } from './services/template.service';
import { ObjectComplexSettingsFrontService } from './modules/@object-complex/service/settings-front.service';
import { ObjectHousingSettingsFrontService } from './modules/@object-housing/service/settings-front.service';
import { HousingSettingsFrontService } from './modules/@objects-search/service/settings-front.service';
import { ObjectComplexSettingsEmbededService } from './modules/@object-complex/service/settings-embeded.service';
import { ObjectHousingSettingsEmbededService } from './modules/@object-housing/service/settings-embeded.service';
import { HousingSettingsEmbededService } from './modules/@objects-search/service/settings-embeded.service';
import {AuthenticateService} from './services/authenticate.service';
import {StyleControlService} from './services/style-control.service';
// import * as Sentry from '@sentry/angular-ivy';


import {
  JsonClientService,
  JsonErrorHandler303Interceptor,
  JsonErrorHandler401Interceptor,
  JsonSessionInterceptor
} from './shared/json-client';

import {
  StyleRestoreFactory,
  IFrameDataRestoreFactory,
} from './shared/factories';
import {DeactivateService} from './shared/housing/shared/guards';
import {UserAgentService} from './services';
import {FancyboxService} from './shared/fancybox.service';
import {NgxMaskDirective, provideNgxMask} from 'ngx-mask';
import {NotificationService} from './services/notification.service';


@NgModule({
  declarations: [
    AppEmbededComponent,
  ],
  imports: [
    CommonModule,
    NgxMaskDirective,
    AppEmbededRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FlatinfoEmbededModule,
    // -- Errors loading
    Error400Module,
    Error403Module,
  ],
  providers: [
    // {
    //   provide: ErrorHandler,
    //   useValue: Sentry.createErrorHandler({
    //     showDialog: false,
    //   }),
    // }, {
    //   provide: Sentry.TraceService,
    //   deps: [Router],
    // },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: () => () => {},
    //   deps: [Sentry.TraceService],
    //   multi: true,
    // },
    ValidHashGuard,
    ConfigService,
    HttpClient,
    DictionariesService,
    LockerService,
    EmbededService,
    TemplateService,
    ClientModeService,
    AuthenticateService,
    StyleControlService,
    JsonClientService,
    UserAgentService,
    // -- filled form close warning
    DeactivateService,
    // -- HTTP Session inject
    {provide: HttpSessionService, useExisting: HttpSessionHashService},
    HttpSessionHashService,
    // -- Housing base URI settings
    {provide: HousingSettingsFrontService, useExisting: HousingSettingsEmbededService},
    HousingSettingsEmbededService,
    // -- Housing object URI settings
    {provide: ObjectHousingSettingsFrontService, useExisting: ObjectHousingSettingsEmbededService},
    ObjectHousingSettingsEmbededService,
    {provide: ObjectComplexSettingsFrontService, useExisting: ObjectComplexSettingsEmbededService},
    ObjectComplexSettingsEmbededService,
    FancyboxService,
    {provide: HTTP_INTERCEPTORS, useClass: JsonSessionInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: JsonErrorHandler303Interceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: JsonErrorHandler401Interceptor, multi: true},

    {provide: APP_INITIALIZER, useFactory: IFrameDataRestoreFactory,
      deps: [AuthenticateService, HttpSessionService], multi: true},
    {provide: APP_INITIALIZER, useFactory: StyleRestoreFactory,
      deps: [StyleControlService, AuthenticateService], multi: true},
    provideNgxMask(),
    NotificationService,
  ],
  bootstrap: [
    AppEmbededComponent,
    FlatinfoEmbededComponent,
  ]
})
export class AppModule {
}
