import {
  Component, OnDestroy, Optional, Renderer2, TemplateRef, ViewChild
} from '@angular/core';
import {FlatinfoComponent} from './flatinfo.component';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {FlatinfoService} from '../service/flatinfo.service';
import {HttpClient} from '../../../services/http.service';
import {NotificationService} from '../../../services/notification.service';
import { ConfigService } from '../../../services/configuration.service';
import {EmbededService} from '../../../services/embeded.service.';
import {Router} from '@angular/router';
import {ClientModeService} from '../../../_front/client-mode/client-mode.service';
import {FancyboxService} from '../../../shared/fancybox.service';

@Component({
  selector: 'app-flatinfo-layout',
  templateUrl: './flatinfo-embeded.component.html',
  styleUrls: ['./flatinfo.component.scss'],
})
export class FlatinfoEmbededComponent extends FlatinfoComponent implements OnDestroy {
  public readonly is_embeded: boolean = true;

  public modal_caption = null;
  public modal_content = null;
  public modal_footer = null;

  public formWebsite: UntypedFormGroup;

  /*
   * Is embedded application?
   * True - cause element would hidden by default
   */
  public embedded_mode = true;


  @ViewChild('saitFooterTmpl', {static: false})
  private saitFooterTmpl: TemplateRef<any>;

  @ViewChild('saitContentTmpl', {static: false})
  private saitContentTmpl: TemplateRef<any>;

  constructor(public config: ConfigService, flatinfoService: FlatinfoService, public http: HttpClient,
              public lightbox: FancyboxService, public renderer: Renderer2, public fb: UntypedFormBuilder,
              public router: Router, public clientModeService: ClientModeService,
              @Optional() embeddedService: EmbededService) {
    super(config, flatinfoService, http, lightbox, renderer, router, clientModeService);

    /*
     * Embedded service must be allowed only in embedded application
     */
    this.embedded_mode = embeddedService !== null;
  }

  getFormSait() {
    this.formWebsite = this.fb.group({
      'fio': ['', [Validators.required]],
      'email': [''],
      'phone': ['', [Validators.required]],
    });
  }

  onWebsite() {
    this.getFormSait();
    this.modal_caption = 'Заявка с сайта';
    this.modal_content = this.saitContentTmpl;
    this.modal_footer = this.saitFooterTmpl;
  }

  onSaveWebsite() {
    this.http.post('Integration.iframe_email_notify', {
      'pricelist_id': +this.flat_id,
      'price_id': +this.flat_id,
      'fio': this.formWebsite.value.fio,
      'email': this.formWebsite.value.email,
      'phone': this.formWebsite.value.phone,
    }).subscribe(
      (response) => {
        this.onCloseModel();
        this.formWebsite.reset();
        NotificationService.swalSuccess('Поздравляем', 'Заявка успешно создана');
      },
      (error) => {
        NotificationService.swalError('Ошибка', 'Не удалось создать заявку');
      }
    );
  }

  public onCloseModel() {
    this.modal_caption = null;
    this.modal_content = null;
    this.modal_footer = null;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
