<div id="tooltip-wrapper" class="tooltip-wrapper"></div>

<section class="header" *ngIf="header || backref_url">
  <a *ngIf="backref_url" class="header__backref backref__btn" [routerLink]="[backref_url['url']]"
     [queryParams]="backref_url['params']">
    <i class="icon icon_back"></i>
  </a>
  <div class="header__caption">
    <h1>{{header}}</h1>
  </div>
  <div class="header__logos">
    <!--
    <a href="javascript: void(0)" routerLink="/" class="logo logo_spn float-right">
      <img class="logo__img" [src]="'./assets/images/Logo.svg'" alt="SPN24">
    </a>
    -->
  </div>
</section>

<div class="p-3 pt-4">
  <router-outlet></router-outlet>
</div>

<div class="oferta mb-4 mr-3">
  Информация предоставлена © SPN24.RU | Не является офертой.
</div>
