import {NgModule} from '@angular/core';
import {FlatinfoService} from './service/flatinfo.service';
import {AccordionModule} from '../@accordion/accordion.module';
import {SharedModule} from '../../shared/shared.module';
import {MaterialInputModule} from '../@material-input';
import {PreloaderModule} from '../@preloader/preloader.module';
import {FlatinfoComponent} from './component/flatinfo.component';
import {PopoverModule} from '../@popover/popober.module';


@NgModule({
    imports: [
        AccordionModule,
        SharedModule,
        MaterialInputModule,
        PopoverModule,
        PreloaderModule,
    ],
    declarations: [
        FlatinfoComponent,
    ],
    providers: [
        FlatinfoService,
    ],
    exports: [
        SharedModule,
        MaterialInputModule,
        AccordionModule,
        PreloaderModule,
        FlatinfoComponent,
    ]
})
export class FlatinfoModule {

}
