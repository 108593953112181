<div class="wrapper">
  <div class="loader-inner" [ngClass]="cssClass">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>

</div>
