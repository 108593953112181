import {Injectable} from '@angular/core';
import {JsonClientService} from '../shared/json-client';

@Injectable()
export class StyleControlService {

  constructor(private json: JsonClientService) { }

  private oldColors = {
    '--clr-red': getComputedStyle(document.body).getPropertyValue('--clr-red-default').trim().replace(/\s/g, ''),
    '--clr-yellow': getComputedStyle(document.body).getPropertyValue('--clr-yellow-default').trim().replace(/\s/g, ''),
  }

  private newColors: {}

  private arrayOfColors = {
    '--clr-red': [
      'clr-red-light',
      'clr-red-original',
      'clr-red-bright',
      'clr-red-dark',
      'clr-red-darker',
      'clr-red-darkest',
      'clr-pink',
      'clr-pink-light',
      'clr-pink-light',
      'clr-pink-dark',
      'clr-pink-border-dark',
    ],
    '--clr-yellow': [
      'clr-yellow-opacity',
      'clr-yellow-opacity-2x',
      'clr-yellow-opacity-3x',
      'clr-yellow-dark',
      'clr-yellow-border-dark',
      'clr-yellow-search-start',
      'clr-yellow-search-end',
    ],
  }


  public restoreStyles() {
    return new Promise<void>(resolve => {

      this.json.post('Session.get_css_props')
        .then(
          response => {
            if (response) {
             if (response.special_scheme) {

               if (response.css_props) {
                 for (const key of Object.keys(response.css_props)) {
                   document.documentElement.style.setProperty(`--` + key, response.css_props[key]);
                 }
               }

             } else {

               if (response.css_props) {
                 this.newColors = response.css_props;
                 this.differenceCheck();
               }

             }

            }
            resolve();
          },
          error => {
            resolve();
          });

    });
  }

  public differenceCheck(colorObject = null) {

    let defaultCheck = false;
    let counter = 0;

    if (!colorObject) {
      colorObject = this.newColors;
    }



    if (this.oldColors['--clr-red'] === colorObject['--clr-red'] && this.oldColors['--clr-yellow'] === colorObject['--clr-yellow']) {
      defaultCheck = true;
    }

    for (const [oldCOlorKey, oldCOlorValue]  of Object.entries(this.oldColors)) {

      const originalColor: Array<any> = oldCOlorValue.substring(oldCOlorValue.indexOf('(') + 1, oldCOlorValue.lastIndexOf(')')).split(/,\s*/);
      document.documentElement.style.setProperty(oldCOlorKey, colorObject[oldCOlorKey]);

      const differenceColor: Array<any> = colorObject[oldCOlorKey].substring(colorObject[oldCOlorKey].indexOf('(') + 1, colorObject[oldCOlorKey].lastIndexOf(')')).split(/,\s*/);

      if (defaultCheck) {
        for (let i = 0; i < 3; i++) {
          differenceColor[i] = originalColor[i] - differenceColor[i];
        }
      }

      // const hsp = Math.sqrt(0.299 * (differenceColor[0] * differenceColor[0]) +
      //   + 0.587 * (differenceColor[1] * differenceColor[1]) +
      //   + 0.114 * (differenceColor[2] * differenceColor[2]));
      const avg = (1 * differenceColor[0] + 1 * differenceColor[1] + 1 * differenceColor[2]) / 3;

      if (avg > 150) {
        document.documentElement.style.setProperty(oldCOlorKey + `-inside`, 'rgb(0, 0, 0)');
      } else {
        document.documentElement.style.setProperty(oldCOlorKey + `-inside`, 'rgb(255, 255, 255)');
      }

      for (const defaultColor of this.arrayOfColors[oldCOlorKey]) {

        const defaultColorRGBA = getComputedStyle(document.body).getPropertyValue('--' + defaultColor + '-default');
        const newColorArray: Array<any> = defaultColorRGBA.substring(defaultColorRGBA.indexOf('(') + 1, defaultColorRGBA.lastIndexOf(')')).split(/,\s*/);

        for (let i = 0; i < 3; i++) {

          let newLineColor: number;

          if (defaultCheck) {
            newLineColor = parseInt(newColorArray[i], 10) - differenceColor[i];
          } else {
            newLineColor = differenceColor[i];
          }
          if (defaultColor === 'clr-yellow-search-start' || defaultColor === 'clr-yellow-search-end') {
            newLineColor = newLineColor - 10;
          }
          if (newLineColor > 255) {
            newColorArray[i] = 255;
          } else if (newLineColor < 0) {
            newColorArray[i] = 0;
          } else {
            newColorArray[i] = newLineColor;
          }

        }


        let rgb;
        if (newColorArray.length > 3) {
          rgb = 'rgba(' + newColorArray[0] + ',' + newColorArray[1] + ',' + newColorArray[2] + ',' + newColorArray[3] + ')';
        } else {
          rgb = 'rgb(' + newColorArray[0] + ',' + newColorArray[1] + ',' + newColorArray[2] + ')';
        }

        document.documentElement.style.setProperty(`--` + defaultColor, rgb);

      }
      counter++;

    }


  }

}
