import {Component, OnDestroy} from '@angular/core';
import {NavigationEnd, NavigationError, NavigationStart, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {LockerService} from './services/locker.service';
import {EmbededService} from './services/embeded.service.';
import {TemplateService} from './services/template.service';
import { filter } from 'rxjs/operators';

function onElementHeightChange(elm, callback) {
    let lastHeight = elm.clientHeight, newHeight;
    (function run() {
        newHeight = elm.clientHeight;
        if (lastHeight !== newHeight) {
          callback();
        }

        lastHeight = newHeight;

        if (elm.onElementHeightChangeTimer) {
          clearTimeout(elm.onElementHeightChangeTimer);
        }

        elm.onElementHeightChangeTimer = setTimeout(run, 200);
    })();
}

@Component({
  selector: 'app-root',
  templateUrl: './app-embeded.component.html'
})
export class AppEmbededComponent implements OnDestroy {

  private subscription: Subscription;

  public header: string;

  private backref_subscription_route: Subscription;
  private backref_subscription_service: Subscription;
  public backref_url: {[key: string]: any} = null;

  constructor(private router: Router, public embeddedService: EmbededService, private template: TemplateService) {
    this.subscription = this.router.events
      .pipe(filter(evt => evt instanceof NavigationEnd || evt instanceof NavigationError || evt instanceof NavigationStart))
      .subscribe(evt => {
        if (evt instanceof NavigationStart) {
          LockerService.lock();
        } else {
          LockerService.unlock();
          embeddedService.emit();
        }
      });

    /*
     * Destroying backref url every NaginationEnd event
     */
    this.backref_subscription_route = this.router.events
      .pipe(filter(x => x instanceof NavigationEnd || x instanceof NavigationError))
      .subscribe(x => {
        this.backref_url = null;
        this.header = null;
      });

    /*
     * Changing backref and header from template service
     */
    this.backref_subscription_service = this.template.backref.subscribe(x => this.backref_url = x);
    this.template.header.subscribe((header) => this.header = header);

    onElementHeightChange(document.body, () => {
      embeddedService.emit();
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    /*
     * Unsubscribe this piece of sh!t
     */
    if (this.backref_subscription_route) {
      this.backref_subscription_route.unsubscribe();
    }

    if (this.backref_subscription_service) {
      this.backref_subscription_service.unsubscribe();
    }
  }

}
