import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertComponent } from './component/alert.component';
import { AlertInfoComponent } from './component/alert-info.component';
import { AlertPrimaryComponent } from './component/alert-primary.component';
import { AlertWarningComponent } from './component/alert-warning.component';
import { AlertDangerComponent } from './component/alert-danger.component';
import { AlertDefaultComponent } from './component/alert-default.component';
import { AlertSuccessComponent } from './component/alert-success.component';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    AlertComponent,
    AlertInfoComponent,
    AlertPrimaryComponent,
    AlertWarningComponent,
    AlertDangerComponent,
    AlertDefaultComponent,
    AlertSuccessComponent,
  ],
  exports: [
    AlertComponent,
    AlertInfoComponent,
    AlertPrimaryComponent,
    AlertWarningComponent,
    AlertDangerComponent,
    AlertDefaultComponent,
    AlertSuccessComponent,
  ]
})
export class AlertModule { }
