import {Injectable} from '@angular/core';
import {ISessionInject} from '../models/session-inject';

@Injectable()
export class HttpSessionService implements ISessionInject {

  public token_name = 'WWWToken';

  public getHeaderCaption(): string {
    return this.token_name;
  }

  public setSessionID(value: string): void {

  }

  public getSessionID(): string {
    return null;
  }

}
