
import {tap} from 'rxjs/operators';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpSessionService} from '../../../services/http-session.service';
import {ActivatedRoute, Router} from '@angular/router';

import {ConfigService} from '../../../services/configuration.service';
import {AuthenticateService} from '../../../services/authenticate.service';

@Injectable()
export class JsonErrorHandler401Interceptor implements HttpInterceptor {

  constructor(private session: HttpSessionService, private router: Router, private route: ActivatedRoute) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(req).pipe(tap((event: HttpEvent<any>) => {}, e => {
      if (e.status === 401 || (e.error && e.error.code === 40010)) {
        /*
         * Если с сервера пришла ошибка о протухшей сессии
         * и мы находимся в системе
         */
        this.session.setSessionID(null);
        if (this.route.firstChild != null &&
            this.route.firstChild.routeConfig.path !== 'authenticate'
        ) {
          location.href = '/authenticate?returnUrl=' + this.router.url;
          localStorage.removeItem('asterisk-web-user-4a087b99-1a16-4db6-a318-50dbc134091c');
        } else if (location.href.indexOf('authenticate') === -1 ) {
          location.href = '/authenticate/';
          localStorage.removeItem('asterisk-web-user-4a087b99-1a16-4db6-a318-50dbc134091c');
        }
      }
    }));

  }

}
