import {AuthenticateService} from "../../services/authenticate.service";
import {HttpSessionService} from "../../services/http-session.service";

export function IFrameDataRestoreFactory(auth: AuthenticateService, session: HttpSessionService) {
  return () => {
    const urlParams = new URLSearchParams(document.location.search);
    const session_id = urlParams.get('hash');

    session.setSessionID(session_id);

    auth.loadIFrameStartData();
  }
}
