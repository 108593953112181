import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PanelComponent } from './component/panel.component';
import { PanelScrollComponent } from './component/panel-scroll.component';
import { PanelCardComponent } from './component/panel-card.component';


@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    PanelComponent,
    PanelScrollComponent,
    PanelCardComponent
  ],
  exports: [
    PanelComponent,
    PanelScrollComponent,
    PanelCardComponent
  ]
})
export class PanelModule { }
