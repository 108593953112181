import {Injectable} from '@angular/core';
import {IObjectComplexSettings} from './settings.interface';

@Injectable()
export class ObjectComplexSettingsEmbededService implements IObjectComplexSettings {

  public backrefUri = '/home/objects';

  public getBackrefUri(): string {
    return this.backrefUri;
  }

  public setBackrefUri(uri: string) {
    return this.backrefUri = uri;
  }
}
