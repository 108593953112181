import {EventEmitter, Injectable} from '@angular/core';

@Injectable()
export class TemplateService {

  public header: EventEmitter<any> = new EventEmitter<any>();
  public mobileMenuOpen: EventEmitter<any> = new EventEmitter<any>();
  public backref: EventEmitter<{[key: string]: any}> = new EventEmitter<{[key: string]: any}>();

  public setHeader(text) {
    this.header.emit(text);
  }

  public setBackref(url: {[key: string]: any}) {
    this.backref.emit(url);
  }

  constructor() { }

}
