import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';

declare const parent;

@Injectable()
export class EmbededService {

  emit() {
    const bodyHeight = document.getElementsByTagName('body')[0].clientHeight,
      command = {'type': 'FRAME_HEIGHT', 'height': bodyHeight};
    parent.postMessage(JSON.stringify(command), '*');
  }

}
