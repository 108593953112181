<div class="form__group" [ngClass]="cssClass">
  <div class="form__label" [class.form__label_require]="require && !disabled" [class.form__label_error]="error">
    <ng-content></ng-content>
  </div>
  <div class="form__input" [class.form__input_disabled]="disabled" [class.form__input_focus]="focus"
       (mouseenter)="hovered = true" (mouseleave)="hovered = false"
       [class.form__input_error]="error" [formGroup]="formG">
    <input [type]="type"
           [prefix]="mask.indexOf('+7 ') === 0 ? '+7 ' : ''"
           [mask]="mask.indexOf('+7 ') === 0 ? mask.replace('+7 ', '') : mask"
           [clearIfNotMatch]="clearIfNotMatch"
           [showMaskTyped]="true"
           class="input"
           formControlName="inp"
           (focus)="focus = true"
           (blur)="focus = false"
           (keyup)="onChanges($event.target)"
           (paste)="onPaste($event)"
           [ngClass]="{'md-valid': current_value && (current_value.length || current_value > 0)}"
           [placeholder]="placeholder"
    />
    <ng-container *ngIf="remove && current_value && hovered">
      <button class="form__input-close" (click)="onRemove($event)" tabindex="-1">×</button>
    </ng-container>
  </div>
</div>
