import {Injectable} from '@angular/core';
import {ISettings} from './settings.interface';

@Injectable()
export class HousingSettingsEmbededService implements ISettings {

  public getHousingUri(): string {
    return '/home';
  }

  public getBackrefUri(): string {
    return '/home/objects';
  }
}
