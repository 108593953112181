import {Injectable} from '@angular/core';
import {IObjectHousingSettings} from './settings.interface';

@Injectable()
export class ObjectHousingSettingsFrontService implements IObjectHousingSettings {

  public backrefUri = '/objects/search';
  public basefUri = '/objects';

  public getBackrefUri(): string {
    return this.backrefUri;
  }

  public setBackrefUri(uri: string) {
    return this.backrefUri = uri;
  }

  public setBackrefDefault() {
    return this.backrefUri = '/objects/search';
  }

}
