import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'phone'
})
export class PhonePipe implements PipeTransform {
    transform(phone: any, args?: any[]) {
        if (!phone) {
          return;
        }
        phone = ('' + phone).replace(/\D+/g, '').substr(-10);
        phone = phone.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, `+7 ($1) $2-$3-$4`);
        return phone;
    }
}
