import {HttpSessionService} from '../../../services/http-session.service';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';

@Injectable()
export class JsonSessionInterceptor implements HttpInterceptor {

  private readonly token_type_exception = new Map<string, string>(Object.entries({
    'Session.signin': null,
    'Integration.signin': null,
    'System.check': null,
    // 'Webinar.status': null,
    'Subagent.create_organisation': 'WWWWizard',
    'Organisation.get_organisation_wizard': 'WWWWizard',
    'NewEmployee.create_register_request': 'WWWWizard',
    'NewEmployee.get_register_request': 'WWWWizard',
    'NewEmployee.cancel_employee_request': 'WWWWizard',
  }));

  private default_token = 'WWWToken';

  private static getRequestMethod(req: HttpRequest<any>) {
    let request;

    try {
      request = JSON.parse(req.body);
    } catch (e) {
      return;
    }

    return request['method'];
  }

  constructor(private session: HttpSessionService) {
    this.default_token = this.session.getHeaderCaption();
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    /*
     * Подписывает каждый запрос идентификатором сессии
     * Для подписи используется токен по-умолчанию,
     * если иное не определено в переменной token_type_exception
     */
    const session = this.session.getSessionID();
    const method = JsonSessionInterceptor.getRequestMethod(req);

    let headerCaption = this.default_token;

    if (method && this.token_type_exception.has(method)) {
      headerCaption = this.token_type_exception.get(method);
    }

    if (headerCaption && session) {
      const authString = session && headerCaption ? headerCaption + ' ' + session : '';
      const request = req.clone({
        setHeaders: {
          'Content-Type': 'application/json',
          'Authorization': authString
        }
      });
      return next.handle(request);
    }

    return next.handle(req);

  }
}
