import {EventEmitter, Injectable} from '@angular/core';
import { Operation } from '../../../models/operation.enum';


@Injectable()
export class FlatinfoService {

  public flat_id: EventEmitter<number> = new EventEmitter();
  public appeal_id: EventEmitter<number> = new EventEmitter();

  public operation: EventEmitter<Operation> = new EventEmitter();
  public refreshViewTable: EventEmitter<any> = new EventEmitter<any>();

  public display(flat_id: number, appeal_id?: number): void {
    this.flat_id.emit(flat_id);
    if (appeal_id) {
      this.appeal_id.emit(appeal_id);
    } else {
      this.appeal_id.emit(null);
    }
  }

  setOperation(operation: Operation): void {
    this.operation.emit(operation);
  }

}
