import {EventEmitter, Injectable} from '@angular/core';

@Injectable()
export class NavigationLoaderService {

  public static state_emitter: EventEmitter<boolean> = new EventEmitter();

  public static show() {
    this.state_emitter.emit(true);
  }

  public static hide() {
    this.state_emitter.emit(false);
  }

}
