<div class="new_panel" [ngClass]="cssClass">
  <div class="panel__header" *ngIf="caption">
    <div class="row no-gutters gutters-2 align-items-center">
      <div [ngClass]="{'col-md-6 col-lg-8 col-xl-8': headerRight, 'col-md-12': !headerRight}">
        <i class="{{icon}}" *ngIf="icon"></i> {{caption}}
      </div>
      <div class="col-md-6 col-lg-4 col-xl-4"  *ngIf="headerRight">
        <ng-template [ngTemplateOutlet]="headerRight"></ng-template>
      </div>
    </div>
  </div>
  <div class="panel__content" [ngClass]="cssBody">
    <ng-content></ng-content>
  </div>
  <div class="panel__footer" *ngIf="footer">
      <ng-template [ngTemplateOutlet]="footer"></ng-template>
  </div>
</div>
