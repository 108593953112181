import {NgModule} from '@angular/core';
import {AppSidebarDropdownDirective, DropdownDirective, SidebarCloseMobileDirective, SidebarToggleDirective } from './ablepro.directives';

@NgModule({
  declarations: [
    DropdownDirective,
    SidebarToggleDirective,
    SidebarCloseMobileDirective,
    AppSidebarDropdownDirective,
  ],
  exports: [
    DropdownDirective,
    SidebarToggleDirective,
    SidebarCloseMobileDirective,
    AppSidebarDropdownDirective,
  ]
})
export class AbleproDirectivesModule { }
