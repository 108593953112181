import { Component, Input, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss']
})
export class PanelComponent implements OnInit {

  @Input() public caption: string = null;
  @Input() public cssClass = '';
  @Input() public icon: string = null;
  @Input() public cssBody: string[] = null;
  @Input() public footer: TemplateRef<any>;
  @Input() public headerRight: TemplateRef<any>;

  constructor() { }

  ngOnInit() {
  }

}
