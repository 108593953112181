import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PopoverDirective } from './component/popover.directive';
import { PopoverContentComponent } from './component/popover-content.component';

export * from './component/popover.directive';
export * from './component/popover-content.component';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        PopoverContentComponent,
        PopoverDirective,
    ],
    exports: [
        PopoverContentComponent,
        PopoverDirective,
    ]
})
export class PopoverModule {

}
