import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
// import * as Sentry from '@sentry/angular-ivy';

import { AppModule } from './app/app-embeded.module';
import { environment } from './environments/environment';

// Sentry.init({
//   dsn: 'https://d1ab7e2fd9fa9a3398a9f4711aaa2314@o1031706.ingest.sentry.io/4505917429645312',
//   integrations: [
//     new Sentry.BrowserTracing({
//       // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//       tracePropagationTargets: ['localhost', /^https:\/\/spn24\.ru\/api/],
//       routingInstrumentation: Sentry.routingInstrumentation,
//     }),
//     new Sentry.Replay(),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0,
//   replaysSessionSampleRate: 0.1,
//   replaysOnErrorSampleRate: 1.0,
// });

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then((success) => console.log('Bootstrap success'))
  .catch((err) => console.error(err));
