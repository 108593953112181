
import {from as observableFrom, BehaviorSubject, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {AnonymousUser, User} from '../models/user';
import { environment } from '../../environments/environment';
import {ConfigService} from './configuration.service';
import {StyleControlService} from './style-control.service';

@Injectable()
export class AuthenticateService {

  public static readonly key = '4a087b99-1a16-4db6-a318-50dbc134091c';
  public static readonly user_key = 'asterisk-web-user-' + AuthenticateService.key;
  public static readonly config_key = 'spn-web1-' + ConfigService.key;
  public static readonly autocomplete_key = 'asterisk-web-autocomplite-' + AuthenticateService.key;

  public static session_id: string = null;

  public user_obj: User;

  /*
   * Флаг используется для синхронизации factories
   * для корректной обработки их запросов к серверу
   */
  public initialized: Promise<User>;

  private initialized_state = false;
  private initializedResolver = (...args) => null;

  /*
   * Хз что такое "autocomplite", но пусть остается :)
   */
  private _autocomplete = environment.autocomplite;

  /*
   * Секция для работы с пользовательскими данными
   * Зарефакторил со статичной переменной + EventEmitter на BehaviorSubject
   * Это даст возможность убрать лишнее присвоение статичной переменной
   * во всех используемых классах и компонентах
   */

  private user_subject = new BehaviorSubject<User>(new AnonymousUser());

  public readonly emit_user: Observable<User> =
    observableFrom(this.user_subject);

  public get user(): User {
    return this.user_subject.getValue();
  }

  public set user(value: User) {
    this.user_subject.next(value);
    this.user_obj = value;

    if (this.initialized && !this.initialized_state) {
      this.initializedResolver(value);
      this.initialized_state = true;
    }

    /*
     * Оставлено для обратной совместимости
     * TODO Убрать при переходе на полноценные интерцепторы
     */
    AuthenticateService.session_id = this.user.session_id;
  }

  get session_id() {
    /*
     * Добавлено получение идентификатора сессии из не-статичного класса
     */
    return this.user.session_id;
  }

  get page_viewed() {
    return this.user.help_page_viewed;
  }

  get is_tariff() {
    return this.user && this.user.access['tariff_card'];
  }

  /*
   * Секции для работы с опциями Autocomplet'а.
   * Эта штука оставлена для совместимости с демо-стендами
   * И, да, хранить пароли в localStorage - верх безопасности.
   */
  private autocomplete_subject = new BehaviorSubject<any>(null);

  public readonly emit_autocomplete: Observable<any> =
    observableFrom(this.autocomplete_subject);

  public get autocomplete() {
    return this.autocomplete_subject.getValue();
  }

  public set autocomplete(value) {
    /*
     * Сеттер для Autocomplet'а
     * Задает значение и сохраняет его в localStorage
     * Убивает значение в случае null
     */
    this.autocomplete_subject.next(value);

    if (value === null) {
      localStorage.removeItem(AuthenticateService.autocomplete_key);
    } else {
      const autocomplite_data = JSON.stringify(value);

      localStorage
        .setItem(AuthenticateService.autocomplete_key, autocomplite_data);
    }
  }

  public get is_autocomplete() {
    return this._autocomplete;
  }

  /*
   * Статические функции
   */
  private static load_data(): User {
    /*
     * Загружает данные из localStorage и возвращает экземпляр класса
     * пользователя
     */
    try { return User.from_json(localStorage.getItem(this.user_key)); }
    catch (e) { return; }
  }

  public static exit() {
    /*
     * Обнуляет сессию и убивает значение localStorage
     */
    this.session_id = null;
    localStorage.removeItem(this.user_key);
  }

  constructor(private styleControl: StyleControlService) {
    /*
     * Конструктор объекта со своими зависимостями
     */
    this.initialized =
      new Promise<User>(resolve => this.initializedResolver = resolve);
  }

  /*
   * Методы для работы с пользовательскими данными
   */
  public save_data() {
    /*
     * Сохраняет данные пользователя в localStorage
     */
    const user_data = this.user.to_json();
    localStorage.setItem(AuthenticateService.user_key, user_data);
  }

  public authenticate(username, session_id, email, response) {
    /*
     * Авторизация нового пользователя
     */
    const user = new User(username, session_id, email);
    for (const [key, value] of Object.entries(response)) {
      user.setProperty(key, value);
    }

    this.user = user;
    if (this.user.token_type && this.user.token_type === 'wwwtoken') {
      this.styleControl.restoreStyles();
    }
    this.save_data();
  }

  public saveDataToStorage(data) {
    /*
     * Эта функция используется для сохранения данных
     * их factory сторонней интеграции
     */
    data = JSON.stringify(data);
    localStorage.setItem(AuthenticateService.user_key, data);

    this.user = User.from_json(data);
    this.styleControl.restoreStyles();
  }


  public loadOnStartData() {
    /*
     * Стандартная функция для восстанловления данных пользователя
     */
    this.user = AuthenticateService.load_data() || new AnonymousUser();

    if (this.is_autocomplete) {
      const autocomplete_data =
        localStorage.getItem(AuthenticateService.autocomplete_key);

      this.autocomplete = JSON.parse(autocomplete_data);
    }
  }

  public loadIFrameStartData() {
    /*
     * Загружает неавторизованного пользователя
     * Используется в IFrame
     */
    this.user = new AnonymousUser();
    this.user.setProperty('is_frame_user', true);
  }

  public renewUser() {
    /*
     * Переотсылка данных пользователя
     */
    const user = this.user;
    this.user_subject.next(user);
  }


  /*
   * Методы для работы с Autocomplet'ом
   */
  public saveAutocomplete(login, password) {
    if (this.is_autocomplete) {
      this.autocomplete = {'login': login, 'password': password};
    }
  }

  public removeAutocomplete() {
    this.autocomplete = null;
  }

  public logout() {
    AuthenticateService.session_id = null;
    this.user = new AnonymousUser();

    localStorage.removeItem(AuthenticateService.user_key);
    localStorage.removeItem(AuthenticateService.config_key);

    if (!this.is_autocomplete) {
      this.removeAutocomplete();
    }
  }

  /*
   * Методы для работы с просмотренными страницами помощи
   */
  public addPageViewed(page: string) {
    if (!this.checkPageViewed(page)) {
      this.user.help_page_viewed.push(page);
      this.save_data();
    }
  }

  public checkPageViewed(page: string) {

    if (this.user.help_page_viewed && this.user.help_page_viewed.indexOf('for_all_pages') !== -1) {
      return true;
    } else {
      return this.user.help_page_viewed && this.user.help_page_viewed.indexOf(page) !== -1;
    }

  }
}
