import {NgModule} from '@angular/core';
import {Error403Component} from './error-403.component';
import {PanelModule} from '../../modules/@panel/panel.module';

@NgModule({
  imports: [
    PanelModule,
  ],
  declarations: [
    Error403Component,
  ],
  exports: [
    Error403Component,
  ]
})
export class Error403Module {

}
