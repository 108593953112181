import { NgModule } from '@angular/core';
import { FlatinfoModule } from './flatinfo.module';
import { FlatinfoEmbededComponent } from './component/flatinfo-embeded.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StockBadgeModule } from '../@stock-badge';
import { AngularYandexMapsModule, YaConfig } from 'angular8-yandex-maps';
import { CommonModule } from '@angular/common';


const mapConfig: YaConfig = {
  apikey: 'b0f6c3b9-81d9-4a6d-9dc9-e126feac4550',
  lang: 'ru_RU',
};


@NgModule({
  imports: [
    CommonModule,
    FlatinfoModule,
    FormsModule,
    ReactiveFormsModule,
    StockBadgeModule,
    AngularYandexMapsModule.forRoot(mapConfig),
  ],
  declarations: [
    FlatinfoEmbededComponent,
  ],
  exports: [
    FlatinfoEmbededComponent,
  ]
})
export class FlatinfoEmbededModule {

}
