import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {HttpClient} from '../services/http.service';
import {HttpSessionService} from '../services/http-session.service';
import {AuthenticateService} from '../services/authenticate.service';

@Injectable()
export class ValidHashGuard {

  constructor(private http: HttpClient, private httpSession: HttpSessionService, private router: Router,
              private auth: AuthenticateService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise<boolean>((resolve: (value: boolean) => void) => {
      const hash = route.queryParams['hash'], origin = route.queryParams['origin'];

      this.http.setOrigin(origin);

      if (this.httpSession.getSessionID()) {
        return resolve(true);
      }

      if (!hash) {
        this.router.navigateByUrl('/400');
        return resolve(false);
      }

      this.http.setSessionID(hash);

      this.http.post('Session.uuid_check', {'uuid': hash}).subscribe(response => {
        if (response) {
          this.auth.user.setProperty('session_id', hash);
          return resolve(response);
        }

        this.router.navigateByUrl('/403');
        return resolve(false);
      });
    });
  }

}
