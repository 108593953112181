<div class="flatinfo__layout" [class.flatinfo__layout_open]="isOpen()">
  <div class="flatinfo__layout__mobile red-mobile-header" *ngIf="data">
    <i class="modal__mobile-header__icon icon icon_Small_Dropdown_White mr-2" (click)="closeWindow()"></i>
    <h2>Квартира № {{data['realty_flats_num']}}</h2>
  </div>
  <a class="flatinfo__close" (click)="closeWindow()" href="javascript:void(0)">&times;</a>
    <div class="flatinfo__content" *ngIf="data !== null">
      <div class="flatinfo__content__head">
        <div class="flatinfo__content__head__line_1">
          <h2>Квартира № {{data['realty_flats_num']}}</h2>
        </div>
        <div class="flatinfo__content__head__line_2">
          <span>{{data['realty_caption']}}</span>
        </div>
      </div>
      <div class="flatinfo__content__body-wrapper">
        <div class="flatinfo__overflow">
          <div class="flatinfo__content__body">

            <div class="information">
              <div class="flatinfo__content__attributes">
                <div class="row">
                  <div class="col-5">
                    <p class="line-item">Стоимость кв. метра</p>
                  </div>
                  <div class="col-7">
                    <p class="line-item">
                      <span class="line-item__caption" [innerHTML]="data['realty_flats_price_of_sq_meter'] | priceRank:'md'"></span>
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-5">
                    <p class="line-item">Стоимость квартиры</p>
                  </div>
                  <div class="col-7">
                    <p class="line-item">
                      <ng-container *ngIf="data['realty_flats_price_promo']">
                        <span class="line-item__caption line-through" [innerHTML]="data['realty_flats_price'] | priceRank:'md'"></span>
                        <span class="line-item__caption ml-2" [innerHTML]="data['realty_flats_price_promo'] | priceRank:'md'"></span>
                      </ng-container>
                      <ng-container *ngIf="!data['realty_flats_price_promo']">
                        <span class="line-item__caption" [innerHTML]="data['realty_flats_price'] | priceRank:'md'"></span>
                      </ng-container>
                    </p>
                  </div>
                </div>
                <div class="row mt-3" *ngIf="data['presentation_data']['payment_methods']">
                  <div class="col-5">
                    <p class="line-item">Способы оплаты</p>
                  </div>
                  <div class="col-7">
                    <p class="line-item">
                      <span class="line-item__caption">{{data['presentation_data']['payment_methods']}}</span>
                    </p>
                  </div>
                </div>
                <div class="row mt-3" *ngIf="data['stocks_info'].length">
                  <div class="col-5">
                    <p class="line-item">Текущие акции</p>
                  </div>
                  <div class="col-7">
                    <p class="line-item">
                      <span class="line-item__caption" *ngFor="let stock of data['stocks_info']; let isLast=last">{{stock.badge_caption}}{{isLast ? '' : ', '}} </span>
                    </p>
                  </div>
                </div>
              </div>

              <div class="flatinfo__content__control-btns py-3">
                <button class="button button_block m-0" (click)="onWebsite()">Оставить заявку</button>
              </div>

              <!-- Images -->
              <div class="flatinfo__content__images">
                <div class="img-holder">
                  <div class="element"><img src="{{data['presentation_data']['flat_plan']}}" alt="" (click)="choosePhoto(0)"></div>
                  <div class="element"><img src="{{data['presentation_data']['flat_on_floor_plan']}}" alt="" (click)="choosePhoto(1)"></div>
                </div>
                <ng-container *ngIf="data['presentation_data']['flat_images'] && data['presentation_data']['flat_images'].length">
                  <div class="img-holder">
                      <ng-container *ngFor="let el of data['presentation_data']['flat_images']; let ind=index">
                        <div class="element"><img src="{{el}}" alt="" (click)="choosePhoto(ind + 2)"></div>
                      </ng-container>
                  </div>
                </ng-container>
              </div>
              <!-- /Images -->

              <div class="flatinfo__content__attributes">

                <div class="row mb-3">
                  <div class="col-5">
                    <p class="line-item">Статус объекта</p>
                  </div>
                  <div class="col-7">
                    <p class="line-item">
                      <span class="line-item__caption">
                        <i class="legend__circle"
                           [ngClass]="{'legend__circle_sale': data.status_color==='sale',
                           'legend__circle_reserv': data.status_color==='reserv',
                           'legend__circle_bron': data.status_color==='bron',
                           'legend__circle_sales': data.status_color==='sales'}">
                        </i>
                        <span class="ml-2">{{data.status_caption}}</span>
                      </span>
                    </p>
                  </div>
                </div>
                <div class="row" *ngIf="data['realty_flats_num_pp']">
                  <div class="col-5">
                    <p class="line-item">№ на площадке</p>
                  </div>
                  <div class="col-7">
                    <p class="line-item">
                      <span class="line-item__caption">
                        {{data['realty_flats_num_pp']}}
                      </span>
                    </p>
                  </div>
                </div>
                <div class="row" *ngIf="data['realty_flats_rooms']">
                  <div class="col-5">
                    <p class="line-item">Количество комнат</p>
                  </div>
                  <div class="col-7">
                    <p class="line-item">
                      <span class="line-item__caption">
                        {{data['realty_flats_rooms']}}
                      </span>
                    </p>
                  </div>
                </div>
                <div class="row" *ngIf="data['presentation_data']['section_number']">
                  <div class="col-5">
                    <p class="line-item">Подъезд</p>
                  </div>
                  <div class="col-7">
                    <p class="line-item">
                      <span class="line-item__caption">
                        {{data['presentation_data']['section_number']}}
                      </span>
                    </p>
                  </div>
                </div>
                <div class="row" *ngIf="data['presentation_data']['housing']">
                  <div class="col-5">
                    <p class="line-item">Корпус/дом</p>
                  </div>
                  <div class="col-7">
                    <p class="line-item">
                      <span class="line-item__caption">
                        {{data['presentation_data']['housing']}}
                      </span> (всего {{data['presentation_data']['housings_count']}})
                    </p>
                  </div>
                </div>
                <div class="row" *ngIf="data['realty_flats_floor']">
                  <div class="col-5">
                    <p class="line-item">Этаж</p>
                  </div>
                  <div class="col-7">
                    <p class="line-item">
                      <span class="line-item__caption">
                        {{data['realty_flats_floor']}}
                      </span>
                    </p>
                  </div>
                </div>
                <div class="row" *ngIf="data['realty_flats_total_area']">
                  <div class="col-5">
                    <p class="line-item">Общая площадь</p>
                  </div>
                  <div class="col-7">
                    <p class="line-item">
                      <span class="line-item__caption">
                        {{data['realty_flats_total_area']}}
                      </span>
                    </p>
                  </div>
                </div>
                <div class="row" *ngIf="data['realty_flats_area_of_residential']">
                  <div class="col-5">
                    <p class="line-item">Жилая площадь</p>
                  </div>
                  <div class="col-7">
                    <p class="line-item">
                      <span class="line-item__caption">
                        {{data['realty_flats_area_of_residential']}}
                      </span>
                    </p>
                  </div>
                </div>
                <div class="row" *ngIf="data['realty_flats_area_of_kitchen']">
                  <div class="col-5">
                    <p class="line-item">Площадь кухни</p>
                  </div>
                  <div class="col-7">
                    <p class="line-item">
                      <span class="line-item__caption">
                        {{data['realty_flats_area_of_kitchen']}}
                      </span>
                    </p>
                  </div>
                </div>
                <div class="row" *ngIf="data['realty_flats_area_of_back']">
                  <div class="col-5">
                    <p class="line-item">Площадь подсобных помещений</p>
                  </div>
                  <div class="col-7">
                    <p class="line-item">
                      <span class="line-item__caption">
                        {{data['realty_flats_area_of_back']}}
                      </span>
                    </p>
                  </div>
                </div>
                <div class="row" *ngIf="data['realty_flats_area_of_balconies']">
                  <div class="col-5">
                    <p class="line-item">Площадь балконов</p>
                  </div>
                  <div class="col-7">
                    <p class="line-item">
                      <span class="line-item__caption">
                        {{data['realty_flats_area_of_balconies']}}
                      </span>
                    </p>
                  </div>
                </div>
                <div class="row" *ngIf="data['realty_flats_tip_otd']">
                  <div class="col-5">
                    <p class="line-item">Отделка</p>
                  </div>
                  <div class="col-7">
                    <p class="line-item">
                      <span class="line-item__caption">
                        {{data['realty_flats_tip_otd']}}
                      </span>
                    </p>
                  </div>
                </div>
                <div class="row" *ngIf="data['realty_housings_type']">
                  <div class="col-5">
                    <p class="line-item">Тип дома</p>
                  </div>
                  <div class="col-7">
                    <p class="line-item">
                      <span class="line-item__caption">
                        {{data['realty_housings_type']}}
                      </span>
                    </p>
                  </div>
                </div>
                <div class="row" *ngIf="data['realty_housings_obj_srok_sdachi']">
                  <div class="col-5">
                    <p class="line-item">Срок сдачи</p>
                  </div>
                  <div class="col-7">
                    <p class="line-item">
                      <span class="line-item__caption">
                        {{data['realty_housings_obj_srok_sdachi']}}
                      </span>
                    </p>
                  </div>
                </div>
                <div class="row" *ngIf="data['realty_housings_floors']">
                  <div class="col-5">
                    <p class="line-item">Этажность</p>
                  </div>
                  <div class="col-7">
                    <p class="line-item">
                      <span class="line-item__caption">
                        {{data['realty_housings_floors']}}
                      </span>
                    </p>
                  </div>
                </div>
                <div class="row" *ngIf="data['presentation_data']['lifts_count']">
                  <div class="col-5">
                    <p class="line-item">Лифтов</p>
                  </div>
                  <div class="col-7">
                    <p class="line-item">
                      <span class="line-item__caption" *ngIf="data['realty_flats_num_pp']">
                        {{data['presentation_data']['lifts_count']}}
                      </span> ({{data['presentation_data']['lifts_passenger_count']}} пассажирских, {{data['presentation_data']['lifts_service_count']}} грузовых)
                    </p>
                  </div>
                </div>
              </div>
              <div class="flatinfo__content__adress">
                {{data.realty_housings_address}}
              </div>
              <ng-container *ngIf="(data['info_cardinal_point']['lat'] && data['info_cardinal_point']['lon']) || (data['housing_point']['lat'] && data['housing_point']['lon'])">
                <div style="height: 350px;width:100%;margin-bottom: 100px;">
                  <ya-map
                    [center]="(data['info_cardinal_point']['lat'] && data['info_cardinal_point']['lon']) ?
              [data['info_cardinal_point']['lat'], data['info_cardinal_point']['lon']] : [data['housing_point']['lat'], data['housing_point']['lon']]"
                    [zoom]="16"
                    [state]="{ behaviors: ['drag', 'multiTouch', 'dblClickZoom'], controls: ['smallMapDefaultSet'] }"
                  >
                    <ya-placemark
                      [options]="{
                      iconLayout: 'default#image',
                      iconImageHref: 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyNC4wLjIsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0i0KHQu9C+0LlfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAzMiA0NiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzIgNDY7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+DQoJLnN0MHtmaWxsOiNERTFEMjU7c3Ryb2tlOiNGRkZGRkY7c3Ryb2tlLXdpZHRoOjI7c3Ryb2tlLW1pdGVybGltaXQ6MTA7fQ0KPC9zdHlsZT4NCjx0aXRsZT5Ba3ogLyBNYXAgLyBTaW5nbGUgUE9JPC90aXRsZT4NCjxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPg0KPGcgaWQ9IkRlc2lnbiI+DQoJPGcgaWQ9IkhvbWUtX3gyRl8tTWFwLVZpZXciIHRyYW5zZm9ybT0idHJhbnNsYXRlKC02MjcuMDAwMDAwLCAtNzQzLjAwMDAwMCkiPg0KCQk8ZyBpZD0iQWt6LV94MkZfLU1hcC1feDJGXy1TaW5nbGUtUE9JIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg2MjcuMDAwMDAwLCA3NDMuMDAwMDAwKSI+DQoJCQk8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMTYsMEM3LjIsMCwwLDcuMiwwLDE2YzAsMTIuMSwxNC42LDI4LjksMTUuMywyOS43YzAuMiwwLjIsMC41LDAuMywwLjgsMC4zYzAuMywwLDAuNi0wLjEsMC44LTAuMw0KCQkJCUMxNy40LDQ0LjksMzIsMjguMiwzMiwxNkMzMiw3LjIsMjQuOCwwLDE2LDB6IE0xNiw4bDgsNmgtMnY4SDEwdi04SDhMMTYsOHoiLz4NCgkJPC9nPg0KCTwvZz4NCjwvZz4NCjwvc3ZnPg0K',
                      iconImageSize: [32, 46],
                      iconOffset: [-5, -8],
                      hasBalloon: false,
                      hasHint: false
                    }"
                      [geometry]="(data['info_cardinal_point']['lat'] && data['info_cardinal_point']['lon']) ?
              [data['info_cardinal_point']['lat'], data['info_cardinal_point']['lon']] : [data['housing_point']['lat'], data['housing_point']['lon']]"
                    ></ya-placemark>
                  </ya-map>
                </div>
              </ng-container>
              <div class="flatinfo__content__attributes mt-4">
                <div class="row" *ngIf="data['presentation_data']['lifts_count']">
                  <div class="col-5">
                    <p class="line-item">застройщик/девелопер</p>
                  </div>
                  <div class="col-7">
                    <p class="line-item">
                      <span class="line-item__caption">
                        {{data['presentation_data']['developer_caption']}}
                      </span>
                    </p>
                  </div>
                </div>
                <div class="row" *ngIf="data['presentation_data']['object_class']">
                  <div class="col-5">
                    <p class="line-item">класс объекта</p>
                  </div>
                  <div class="col-7">
                    <p class="line-item">
                      <span class="line-item__caption">
                        {{data['presentation_data']['object_class']}}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>


<ng-template #saitContentTmpl>
  <div *ngIf="data === null" class="loader-wrapper">
    <app-preloader></app-preloader>
  </div>
  <ng-container *ngIf="data !== null">
    <p>Оставьте заявку и наши менеджеры свяжутся с Вами в ближайшее время</p>
    <form class="md-float-material" [formGroup]="formWebsite" novalidate>
      <div>
        <p class="mt-3 mb-4 form__label form__label_require">Поля отмеченные красной точкой обязательны для заполнения.</p>
        <div class="row">
          <div class="col-md-6 col-sm-6">
            <app-material-input formControlName="fio" [require]="true">ФИО</app-material-input>
          </div>
          <div class="col-md-6 col-sm-6">
            <app-material-input-mask [mask]="'+7 (000) 000-00-00'" formControlName="phone" [require]="true">Телефон</app-material-input-mask>
          </div>
          <div class="col-md-6 col-sm-6">
            <app-material-input formControlName="email" [require]="false">Адрес электронной почты</app-material-input>
          </div>
        </div>
      </div>
    </form>
  </ng-container>
</ng-template>
<ng-template #saitFooterTmpl>
  <div class="row">
    <div class="col-sm-6 text-left">
      <button (click)="onSaveWebsite()" [disabled]="!formWebsite.valid"
              class="button button_main" type="button"><i class="fa fa-send"></i> Отправить</button>
    </div>
    <div class="col-sm-6">
      <button (click)="onCloseModel()" class="button pull-right" type="button">Закрыть</button>
    </div>
  </div>
</ng-template>


<app-modal *ngIf="modal_content !== null" [size]="'large'" [backdropClass]="'upper'" [caption]="modal_caption" [footer]="modal_footer" (onClosed)="onCloseModel()" [closeOutside]="false">
  <ng-container [ngTemplateOutlet]="modal_content"></ng-container>
</app-modal>
