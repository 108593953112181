import { Injectable, Optional } from '@angular/core';
import { HttpClient as Http, HttpHeaders } from '@angular/common/http';
import { UUID } from 'angular2-uuid';

import { Observable, throwError as observableThrowError, from } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AuthenticateLoginComponent } from '../shared/authenticate/authenticate-login.component';
import { LockerService } from './locker.service';
import { ConfigService } from './configuration.service';
import { AuthenticateService } from './authenticate.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpSessionService } from './http-session.service';
import { NavigationLoaderService } from '../shared/navigation-loader/navigation-loader.service';
import {NotificationService} from './notification.service';

@Injectable()
export class HttpClient {
  private static api_server = '';
  private static api_token = 'WWWToken';
  private static jsonrpc = '2.0';

  private readonly disable_auth_headers = [
    'System.check',
  ];

  private origin = null;
  private signin_override = null;

  constructor(private http: Http, private config: ConfigService, private httpSession: HttpSessionService,
              private router: Router, private route: ActivatedRoute, private notify: NotificationService,
              @Optional() private auth: AuthenticateService) {
    /*
     * Конструктор объекта
     * Изм 1 - Сервис auth помечен как optional, тк не нужен при авторизации по хэшу
     */
    HttpClient.api_server = this.config.data.api_url;
    HttpClient.api_token = this.httpSession.getHeaderCaption();

    this.config.settings_emitter.subscribe((data) => HttpClient.api_server = data.api_url);
  }

  private static createRPCRequest(method: string, params: any) {
    /*
     * Создает заголовки для JSON-RPC запроса
     */
    return {
      'id': UUID.UUID(),
      'jsonrpc': HttpClient.jsonrpc,
      'method': method,
      'params': params
    };
  }

  public overrideSigninHeader(value: string): void {
    /*
     * Временная перезапись хэдэра подписи авторизации
     */
    if (value === null) {
      HttpClient.api_token = this.httpSession.getHeaderCaption();
      this.httpSession.token_name = 'WWWToken';
    }
    else {
      HttpClient.api_token = value;
      this.httpSession.token_name = value;
    }
  }

  public setSessionID(value: string): void {
    /*
     * Задает идентификатор сесси
     * Актуально для standalone версии приложения
     */
    this.httpSession.setSessionID(value);
  }

  public setOrigin(value: string): void {
    /*
     * Задает origin, актуально для встраиваемого приложения
     */
    this.origin = value;
  }

  post(action: string, params: any = {}) {
    const headers = this.createAuthorization(action);
    const data: string = JSON.stringify(HttpClient.createRPCRequest(action, params));
    return this.http.request('post', HttpClient.api_server, {body: data, headers: headers})
      .pipe(
        map((response) => {
          const r: any = response;
          if (r.error) {
            throw(r);
          }
          NavigationLoaderService.hide();
          return r.result;
        }),
        catchError((handler) => this.handleError(handler))
      );
  }

  async resolvers(resolvers): Promise<any> {
    const data = {};
    for (const resolve of Object.keys(resolvers)) {
      data[resolve] = await from(resolvers[resolve]).toPromise();
    }
    return data;
  }

  private createAuthorization(action) {
    const headers: any = {
      'Content-Type': 'application/json'
    };
    if (this.origin) {
      headers['X-Referrer-Origin'] = this.origin;
    }
    return headers;
  }

  private handleError(handler: any) {
    /*
     *  Предобработка ошибки запроса
     *  Возврат если сервис auth не задан
     */
    NavigationLoaderService.hide();
    if (handler.status === 303) {
      LockerService.server_down();
    }
    if (!this.auth) {
      return observableThrowError(handler);
    }
    if (handler.status === 401 || (handler.error && handler.error.code === 40010)) {
      if (this.httpSession.getSessionID()) {
        // LockerService.lock();
      } else {
        if (this.auth.user['token_type'] && 'wwwwizard' === this.auth.user['token_type']) {
          this.router.navigate(['/register-wizard']);
          return;
        }
      }

      if (this.route.firstChild && this.route.firstChild.routeConfig &&
          this.route.firstChild.routeConfig.path !== 'authenticate') {
          this.router.navigate(['/authenticate'], {queryParams: {returnUrl: this.router.url}});
      }
      /*
       *  Аналогичная работа в интерцепторе, убрать в случае некоректной работы
       */
      // this.auth.logout();
      return observableThrowError(handler);
    }

    if (
      handler.error
      && handler.error.error
      && handler.error.error.data
      && handler.error.error.data.clear_message
    ) {
      this.notify.notifyError('Ошибка', handler.error.error.data.clear_message, 5000);
    } else if (
      handler.error
      && handler.error.error
      && handler.error.error.data
      && handler.error.error.data.message
    ) {
      this.notify.notifyError('Ошибка', handler.error.error.data.message, 5000);
    } else if (
      handler.error
      && handler.error.error
    ) {
      this.notify.notifyError('Ошибка', 'Ошибка на сервере', 5000);
    }

    return observableThrowError(handler);
  }

  server(action: string, data: any = {}, method: string = 'GET') {
    const url = 'http://localhost:3500/';
    return this.http.request(method, url + action, {body: data});
  }
}
