import {Component, ElementRef, HostListener, OnDestroy, Renderer2, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {FlatinfoService} from '../service/flatinfo.service';
import {HttpClient} from '../../../services/http.service';
import { ConfigService } from '../../../services/configuration.service';
import {Router} from '@angular/router';
import {ClientModeService} from '../../../_front/client-mode/client-mode.service';
import {FancyboxService} from '../../../shared/fancybox.service';
import {UntypedFormGroup} from '@angular/forms';


enum Status {
  opened, closed
}


class Photo {
  constructor(public src: string, public thumb: string = null) {
    if (!this.thumb) {
      this.thumb = this.src;
    }
  }
}


class Album {
  public photos: Array<Photo> = [];

  constructor(data: any) {
    this.photos = data.map(x => new Photo(x.link));
  }
}


@Component({
  template: '',
  styleUrls: ['./flatinfo.component.scss'],
})
export class FlatinfoComponent implements OnDestroy {

  public subscription: Subscription = new Subscription();
  private _flat_id: number;

  public readonly is_embeded: boolean = false;
  private readonly has_photos_movement: boolean = false;

  @ViewChild('scrollContainer') public scrollContainer: ElementRef;

  public data: any = null;
  public operationStatus = {
    'active_unique': null,
    'can_unique_check': false,
    'can_view': false,
    'description_string': null
  };
  public album: Album = null;
  public status: Status = Status.closed;
  public images_wrapper_class = 'position-1';
  public client_mode = true;
  public cliendModeSubscription: Subscription;
  public routerSubscription: Subscription;

  public presLink = '';
  public presLinkCopied = false;

  public dotColor = '';
  public form: UntypedFormGroup;

  public clientCardForm = false;

  private readonly image_priority = [
    'layout',
    'flooring',
    'ymap',
    'image',
  ];

  public set flat_id(id: number) {
    this._flat_id = id;
    /*
     * Open layout if its closed
     */
    this.status = Status.opened;
    /*
     * Flush all data
     */
    this.data = null;
    this.album = null;
    /*
     * Loading flat content
     */
    this.loadFlatData();
  }

  public get flat_id(): number {
    return this._flat_id;
  }

  constructor(public config: ConfigService, flatinfoService: FlatinfoService, public http: HttpClient,
              public lightbox: FancyboxService, public renderer: Renderer2, public router: Router,
              public clientModeService: ClientModeService) {
    this.subscription.add(flatinfoService.flat_id.subscribe(x => this.flat_id = x));
    this.client_mode = this.config.getClientMode();
    this.cliendModeSubscription = this.config.client_mode_emitter
      .subscribe(x => {
        this.client_mode = x;
      });
  }

  @HostListener('window:resize', ['$event'])
  public onWindowResize(event) {
    this.maxHeightScroll();
  }

  maxHeightScroll() {
    if (this.scrollContainer) {
      const topScroll = this.scrollContainer.nativeElement.getBoundingClientRect().top;
      const heightScroll = window.innerHeight - (topScroll + 32);
      this.renderer.setStyle(this.scrollContainer.nativeElement, 'maxHeight', heightScroll + 'px');
    }
  }

  closeWindow() {
    this.status = Status.closed;
    this.routerSubscription.unsubscribe();
    this.presLink = '';
    this.presLinkCopied = false;

    this.dotColor = '';
    // this.commentText.p = '';
    setTimeout(_ => {
      this.clientCardForm = false;
    }, 300);
  }

  isOpen() {
    return this.status === Status.opened;
  }

  choosePhoto(number) {
    this.lightbox.open(this.album.photos, number, {wrapAround: true});
  }

  public loadFlatData() {
    this.routerSubscription = this.router.events.subscribe((val) => {
      this.closeWindow();
    });
    const obj = {
      'realty_id': +this.flat_id,
    };
    if (this.clientModeService.client) {
      obj['client_id'] = this.clientModeService.client['id'];
    }
    const promices = [];
    promices.push(this.http.post('Realty.get_full_data', obj).toPromise());
    if (obj['client_id']) {
      promices.push(this.http.post('Realty.get_available_operations', obj).toPromise());
    }

    Promise.all(promices).then((array: Array<any>) => {
      this.data = array[0];
      const imagesList = [
        {
          link: this.data['presentation_data']['flat_plan']
        },
        {
          link: this.data['presentation_data']['flat_on_floor_plan']
        },
      ];
      for (const img of this.data['presentation_data']['flat_images']) {
        imagesList.push({
          link: img
        });
      }

      if (this.form) {
        this.form.get('comment').patchValue(this.data.client_presentation_flat_comment || '', {emitEvent: false, onlySelf: true});
      }

      this.presLink = '';
      this.presLinkCopied = false;

      this.dotColor = '';
      this.album = new Album(imagesList);
      this.maxHeightScroll();
      setTimeout(() => {
        this.maxHeightScroll();
      }, 1000);
      if (typeof array[1] !== 'undefined') {
        this.operationStatus = array[1];
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    if (this.cliendModeSubscription) {
      this.cliendModeSubscription.unsubscribe();
    }
  }

  /*
   * Override this methods!
   */
  createView(): void {
    /*
     * Create view operation for current flat
     */
    return;
  }

  createReserve(): void {
    /*
     * Create reserve operation for current flat
     */
    return;
  }

  createQueue(): void {
    /*
     * Create queue operation for current flat
     */
    return;
  }

}
