import {StyleControlService} from '../../services/style-control.service';
import {AuthenticateService} from '../../services/authenticate.service';

export function StyleRestoreFactory(
  ss: StyleControlService, auth: AuthenticateService) {
  /*
   * Загрузка фирменных стилей при загрузке приложения
   */
  let resolver = (...args) => null;

  const promise = new Promise<void>(resolve => resolver = resolve);

  auth.initialized.then(user => {
    if (user.token_type && user.token_type === 'wwwtoken') {
      ss.restoreStyles().then(_ => resolver());
    }
    else if (user.is_frame_user) {
      ss.restoreStyles().then(_ => resolver());
    }
    else {
      resolver();
    }
  });

  return () => promise;
}
